import { Component, computed, effect, input, output, signal } from '@angular/core';
import { AnalogIn, AnalogOut, DigitalIn, DigitalOut, IOType, RangedKanaal, RangedKanaalAssign } from '../../props/PropTypes';
import { CustomSeriesRenderItemReturn, ECharts, EChartsOption, graphic } from 'echarts';
import { rangedKanaalLabel } from '../kanaal.utils';
import { KanaalSelection } from './KanaalSelectorBase';
import { HwdSysteem } from '../hdw-config/HdwSysteem';
import { KSelectorPresentationType } from '../kanaal.service';



@Component({
  selector: 'kanaal-selector',
  templateUrl: './kanaal-selector.component.html',
  styleUrl: './kanaal-selector.component.scss'
})
export class KanaalSelectorComponent {

  constructor() {
    effect(() => {
      this.presentation = this.initialPresentation();
      this.presentationChanged.emit(this.presentation);
    });
  }

  public kanaalType = input<IOType>();
  public onKanaalSelected = output<KanaalSelection>();
  public range = input<number>(1);
  public presentation: KSelectorPresentationType = "compact";
  public initialPresentation = input<KSelectorPresentationType>("compact");

  public selection = signal<KanaalSelection>({});
  public initialSelection = input<KanaalSelection>({});
  // public presentations: Array<KSelectorPresentationType> = ["compact", "detail", "hardware"];
  public limitPresentations = input<Array<KSelectorPresentationType> | undefined>();

  public presentationChanged = output<KSelectorPresentationType>();


  public onPresentationChanged(event: KSelectorPresentationType) {
    this.presentationChanged.emit(event);
  }

  public presentations = computed(() => {
    if (this.limitPresentations() != undefined) {
      return this.limitPresentations();
    }
    return ["compact", "detail", "hardware"];
  });

  public currentSelection = computed(() => {
    let s =  this.selection();
    if (s.kanaal == undefined) {
      return this.initialSelection();
    }
    return s;
  })

  public assign =  input.required<RangedKanaalAssign[]>();
  public system = input<HwdSysteem>();



  public kanaalLabel(kanaal: number): string {
    let ioType = this.kanaalType();
    if (ioType == undefined) {
      return "";
    }
    return rangedKanaalLabel({
      ioType: ioType,
      range: 1,
      start: kanaal
    }).split(": ")[1];
  }

  public rangedKanaalLabel(rangedKanaal: RangedKanaal): string {
    return rangedKanaalLabel(rangedKanaal);
  }

  public selectionLabel(): string {
    let sel = this.selection();
    let ioType = this.kanaalType();
    if (sel.kanaal != undefined && ioType != undefined) {
      return rangedKanaalLabel({
        ioType: ioType,
        range: this.range(),
        start: sel.kanaal.start
      });
    }
    return "";
  }


  onSelectionChanged(e: KanaalSelection) {
    this.selection.set(e);
    this.onKanaalSelected.emit(e);
  }
}
