import { Component, input } from '@angular/core';
import { RangedKanaal, RangedKanaalAssign } from '../../props/PropTypes';
import { rangedKanaalLabel } from '../kanaal.utils';

@Component({
  selector: 'assigment-detail',
  templateUrl: './assigment-detail.component.html',
  styles: `
  .assignement {
    background-color: var(--light-gray);
  }
`
})
export class AssigmentDetailComponent {

  public assign = input.required<RangedKanaalAssign>();

  public rangedKanaalLabel(rangedKanaal: RangedKanaal): string {
    return rangedKanaalLabel(rangedKanaal);
  }


}
