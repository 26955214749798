import { Component, forwardRef, inject, input, Input, ViewEncapsulation } from '@angular/core';
import { JSBridgeService } from '../jsbridge.service';
import { JSBridge } from '@wasm/KopWeb';
import { KopConfigService } from '../kop-config.service';
import { LDMenuService } from '../ldmenu.service';
import { ModulesRepoService } from '../../modules/modules-repo.service';
import { KopModulesService } from '../../modules/kop-modules.service';
import { ValidationService } from '../../modules/validation.service';
import { KanaalService } from '../../kanaal/kanaal.service';
import { MetaService } from '../meta.service';
import { PropsServiceService } from '../../props/props-service.service';
import { PcibService } from '../pcib.service';
import { KopListService } from '../kop-list.service';
import { HwdConfigService } from '../../kanaal/hwd-config.service';

@Component({
  selector: 'kop-config',
  inputs: ['klantNo'],
  providers: [
    {
      provide: JSBridgeService,
      useClass: JSBridgeService,
    }, KopConfigService, LDMenuService, ModulesRepoService, KopModulesService, ValidationService, KanaalService, MetaService, PropsServiceService, PcibService, KopListService, HwdConfigService
  ],
  encapsulation: ViewEncapsulation.None,
  template: `
  @if (jsBridge == undefined) {
    <div>
      Loading ....
    </div>
  } @else {
    <div class="konfig-container" [ngClass]="{ 'custom-styles': true }">
      <app-bar class="bar"><ng-content select="[app-bar-menu]"></ng-content></app-bar>
      <as-split unit="pixel" [direction]="'horizontal'" >
          <as-split-area size="375" *ngIf="this.kopConfigService.isDetail()">
            <module-bar/>
          </as-split-area>
          <as-split-area size="*">
            <app-afdeling-list class="afd-list"></app-afdeling-list>
          </as-split-area>
          <as-split-area size="500">
            <klassiek-props></klassiek-props>
          </as-split-area>
        </as-split>      
    </div>
  }
  `,
  styleUrl: './kop-config.component.scss'
})
export class KopConfigComponent {

  @Input({}) klantNo: string = "xx";


  private jsBridgeService: JSBridgeService = inject(JSBridgeService);
  public jsBridge: JSBridge | undefined;
  public kopConfigService: KopConfigService = inject(KopConfigService);

  ngOnInit() {
    this.jsBridgeService.getBridge().subscribe(bridge => {
      this.jsBridge = bridge;
    });

    this.jsBridgeService.initKlantNo(this.klantNo);
  }

}
