@let tmSlot = slot();
@if (tmSlot != undefined) {
    @if (modules().length == 1) {
        @let module = modules()[0];
        <div class="module clickable" [class.disabled]="isUsed()" [draggable]="!isUsed()" (dragstart)="onDragStart($event, module)" kendoPopoverAnchor
        [popover]="firstPopover" showOn="hover" (dragend)="onDragEnd($event)">
            <div [style]="{width: '64px', height: '64px'}">
                <asset-icon [imgPath]="module.icon" [defaultPath]="'icons/module.png'"></asset-icon>
            </div>
        <span class="module_name">{{module.name}}</span>
        </div>
        <kendo-popover #firstPopover [title]="module.name" [width]="400" position="right">
            <ng-template kendoPopoverBodyTemplate>
                <div markdown [data]="this.moduleRepo.getDescription(module)"></div>
                <div *ngIf="isCtrlShift">Origin: {{module.origin}}</div>
            </ng-template>

        </kendo-popover>

    } @else { 
        <div #newAnchor class="module clickable" [class.disabled]="isUsed()" 
        kendoPopoverAnchor
                    [popover]="firstPopover" showOn="hover"
            [draggable]="!isUsed()" (dragstart)="onDragStart($event, modules()[0])" (click)="onClick()">
            <kendo-badge-container>
                <div [style]="{width: '64px', height: '64px'}">
                    <asset-icon [imgPath]="tmSlot.icon" [defaultPath]="'icons/slot.png'"></asset-icon>
                </div>
                <kendo-badge themeColor="info" [align]="{vertical: 'bottom', horizontal: 'end'}" class="badge">{{modules().length}}</kendo-badge>
            </kendo-badge-container>
            <span class="module_name">{{tmSlot.name}}</span>
            <kendo-popover #firstPopover [title]="tmSlot.name" [width]="400" position="right">
                <ng-template kendoPopoverBodyTemplate>
                    <div markdown [data]="this.moduleRepo.getDescription(tmSlot)"></div>
                </ng-template>
        
            </kendo-popover>

            
        </div>
        
        <kendo-popup *ngIf="this.isSlotOpen" title="Los Definite" width="400px" 
        [anchor]="newAnchor" 
         #metaPopup>
        <div class="selectMetaWrapper">
            @for (module of modules(); track module.key) {
                <div class="module clickable" [class.disabled]="isUsed()" [draggable]="!isUsed()" (dragstart)="onDragStart($event, module)" kendoPopoverAnchor
                    [popover]="firstPopover" showOn="hover" (dragend)="onDragEnd($event)">
                    <div [style]="{width: '64px', height: '64px'}">
                        <asset-icon [imgPath]="module.icon" [defaultPath]="'icons/module.png'"></asset-icon>
                    </div>
                    <span class="module_name">{{module.name}}</span>
                </div>
                <kendo-popover #firstPopover [title]="module.name" [width]="400" position="right">
                    <ng-template kendoPopoverBodyTemplate>
                        <div markdown [data]="this.moduleRepo.getDescription(module)"></div>
                        <div *ngIf="isCtrlShift">Origin: {{module.origin}}</div>
                    </ng-template>
            
                </kendo-popover>
                }
            </div>
        </kendo-popup>        
    }

}