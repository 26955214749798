import { Component, ContentChildren, Inject, input, output, QueryList } from '@angular/core';
import { RangedKanaalAssign } from '../../props/PropTypes';
import { Router } from '@angular/router';
import { JSBridgeService } from '../jsbridge.service';
import { ServerAPI } from '../../serverapi';
import { HwdConfigService } from '../../kanaal/hwd-config.service';
import { KanaalService, KSelectorPresentationType } from '../../kanaal/kanaal.service';

@Component({
  selector: 'kanaal-overview-dialog',
  templateUrl: './kanaal-overview-dialog.component.html',
  styles: `
  .kanaal-title {
    display: flex;
    flex-direction: row;
    column-gap: 5px;
    // justify-content: space-between;
  }
  .title {
    display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  }`
})
export class KanaalOverviewDialogComponent {

  constructor(public router: Router,public jsBridgeService: JSBridgeService, @Inject('ServerApi') public electronApi: ServerAPI
      , public hdwConfigService: HwdConfigService, public kanaalService: KanaalService) { }

  public onDone = output<boolean>();

  public isShowing = input<boolean>(false);

  public assign =  input.required<RangedKanaalAssign[]>();

  public open() {
    let route = `/KNL/${this.jsBridgeService.getKlantNo()}`;
    let url = this.router.serializeUrl(
      this.router.createUrlTree([route])
    );
    window.open(url, '_blank');
  }


  public onPresentationChanged(p: KSelectorPresentationType) {
    console.log("onPresentationChanged", p);
    this.kanaalService.prefferedPresentation.set(p);
  }
}
