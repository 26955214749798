<div class="kanaal-wrapper">
    @if (this.presentation == "detail") {
    <detailed-kanaal-selector [kanaalType]="this.kanaalType()" (onKanaalSelected)="this.onSelectionChanged($event)"
        [initialSelection]="this.currentSelection()" [range]="this.range()"
        [assign]="this.assign()"></detailed-kanaal-selector>

    } @else if (this.presentation == "compact") {
    <compact-kanaal-selector [kanaalType]="this.kanaalType()" (onKanaalSelected)="this.onSelectionChanged($event)"
        [initialSelection]="this.currentSelection()" [range]="this.range()"
        [assign]="this.assign()"></compact-kanaal-selector>


    } @else {
    <hdwv-kanaal-selector [kanaalType]="this.kanaalType()" (onKanaalSelected)="this.onSelectionChanged($event)"
        [initialSelection]="this.currentSelection()" [range]="this.range()" [system]="this.system()"
        [assign]="this.assign()"></hdwv-kanaal-selector>

    }
    <div class="sidewrapper">
        @let pLabels = this.presentations();
        @if (pLabels != undefined && pLabels.length > 1) {
            <kendo-label text="Presentatie">
                <kendo-dropdownlist [data]="pLabels" [(value)]="this.presentation" (valueChange)="this.onPresentationChanged($event)"></kendo-dropdownlist>
            </kendo-label>

        }
        <div *ngIf="(this.presentation != 'detail') && this.selection().kanaal != undefined" class="assignement-wrapper">
            <div>{{this.selectionLabel()}}</div>
            @for (assign of this.selection().assignement; track $index) {
                <assigment-detail [assign]="assign" />
            }
        </div>

    </div>
</div>