import { Component, signal } from '@angular/core';
import { KopConfigService } from '../../core/kop-config.service';
import { JSBridgeService } from '../../core/jsbridge.service';
import { KanaalService } from '../../kanaal/kanaal.service';
import { KopModulesService } from '../../modules/kop-modules.service';
import { PropsServiceService } from '../props-service.service';

@Component({
  selector: 'klassiek-props',
  templateUrl: './klassiek-props.component.html',
  styleUrl: './klassiek-props.component.scss'
})
export class KlassiekPropsComponent {

  constructor(public kopConfigService: KopConfigService, public jsBridgeService: JSBridgeService,
    public kopModulesService: KopModulesService, public kanaalService: KanaalService, public propsService: PropsServiceService) {

  }

}
