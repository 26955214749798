@let isModule = this.kopModulesService.selectedAfdModule() != undefined;
@if (!isModule) {
    <kendo-label text="Eigenschappen presentatie">
        <kendo-switch class="chooser" onLabel="Nieuwe" offLabel="Klassiek" [(ngModel)]="this.propsService.isKlassiekPresentation"></kendo-switch>
    </kendo-label>        
}
@if (this.propsService.isKlassiekPresentation() && !isModule) {
    @let afdWM = kopConfigService.selectedAfd();
    @let afd = kopConfigService.selectedAfd()?.afdeling();

    @if ((afd != undefined) && (afdWM != undefined)) {
        <div class="buttonsWrapper">
            <klassiek-ai *ngIf="propsService.hasPanelType('AI')"></klassiek-ai>
            <alarm-texts *ngIf="propsService.hasPanelType('AlarmText')" [presentation]="'classic'"></alarm-texts>
            <klassiek-alarms *ngIf="propsService.hasPanelType('Alarms')"/>
            <klassiek-servo *ngIf="propsService.hasPanelType('Servo')"></klassiek-servo>
            <klassiek-los-def *ngIf="propsService.hasPanelType('LosDef')" [type]="'losdef'"></klassiek-los-def>
            <klassiek-bijz-prgms *ngIf="propsService.hasPanelType('BijzPrgs')"></klassiek-bijz-prgms>
            <klassiek-los-def *ngIf="propsService.hasPanelType('Koeling')" [type]="'koeling'"></klassiek-los-def>
            <klassiek-los-def *ngIf="propsService.hasPanelType('DrogWand')" [type]="'droogwand'"></klassiek-los-def>
            <uuren-teksten *ngIf="propsService.hasPanelType('UrenTeksten')" [presentation]="'classic'"></uuren-teksten>
            <!-- <button *ngIf="propsService.hasPanelType('Uren')" kendoButton size="large">Uren tellers</button> -->
            <klassiek-uren *ngIf="propsService.hasPanelType('Uren')"></klassiek-uren>
            <klassiek-los-def *ngIf="propsService.hasPanelType('Verwarming')" [type]="'verwarming'"></klassiek-los-def>
        </div>
    }

} @else { 
    <afd-base-props></afd-base-props>
}