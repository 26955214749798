import { ApplicationRef, effect, EnvironmentInjector, Injectable, signal } from '@angular/core';
import { HdwMeta } from './hdw-config/hdw-meta';
import { HwdSysteem } from './hdw-config/HdwSysteem';
import { MainboardType } from './hdw-config/hdw-konfig-types';
import { NumberPopupComponent } from './hdw/number-popup/number-popup.component';
import { createCustomElement, NgElement, WithProperties } from '@angular/elements';
import { KanaalUpdate, RangedKanaal } from '../props/PropTypes';
import { JSBridgeService } from '../core/jsbridge.service';
import { KanaalService } from './kanaal.service';
import { KopConfigService } from '../core/kop-config.service';

@Injectable({
  providedIn: null
})
export class HwdConfigService {

  constructor(
    private injector: EnvironmentInjector,
    private applicationRef: ApplicationRef,
    private jsBridgeService: JSBridgeService,
    private kanaalService: KanaalService,
    private kopConfigService: KopConfigService
  ) {
    const PopupElement = createCustomElement(NumberPopupComponent, { injector });
    // Register the custom element with the browser.
    customElements.define('popup-element', PopupElement);
    effect(async () => {
      let system = this.system();
      if (system != undefined) {
        setTimeout(() => {
          localStorage.setItem('hdwSystem', JSON.stringify(system.toSerializable()));
        }, 10);
      }
    });
  }


  private _meta: HdwMeta = new HdwMeta();

  public meta(): HdwMeta {
    return this._meta;
  }

  public system = signal<HwdSysteem | undefined>(undefined, { equal: (a, b) => false });

  private findUpdates(oldIos: RangedKanaal[], newIos: RangedKanaal[]) {

    let updateSet: KanaalUpdate[] = [];
    for (let oldIo of oldIos) {
      let newIo = newIos.find(io => io.ioType.equals(oldIo.ioType));
      if (newIo != undefined && newIo.start != oldIo.start) {
        let offset = newIo.start - oldIo.start;
        console.log(`Updating: ${oldIo.ioType.toString()} ${oldIo.start} -> ${oldIo.start + offset} : Offset ${offset}`)
        updateSet.push({
          ranged: oldIo,
          offset: offset
        });
      }
    }
    return updateSet;
  }

  public getUpdateSet(system: HwdSysteem) {
    let oldSystem = this.system();
    let updateSet: KanaalUpdate[] = [];
    if (oldSystem != undefined) {
      let oldBusTypes = oldSystem.getBusTypes();
      for (let busType of oldBusTypes) {
        let oldRacks = oldSystem.getRacks(busType);
        for (let rack of oldRacks) {
          for (let oldKaart of rack.kaarten) {
            let newKaart = system.getKaart(oldKaart.id);
            if (newKaart != undefined) {
              let oldIos = oldKaart.getIOs();
              let newIos = newKaart.getIOs();
              let newUpdates = this.findUpdates(oldIos, newIos);
              if (newUpdates.length > 0) {
                updateSet = updateSet.concat(newUpdates);
              }
            }
          }
        }
      }
    }    
    return updateSet;
  }

  public applyUpdates(updateSet: KanaalUpdate[]) {
    let kfg = this.jsBridgeService.getBridgeSync().getModuleConfig();
    if (kfg != undefined) {
      this.kanaalService.applyUpdates(kfg, updateSet);
      this.kopConfigService.refresh();
    }
  }

  public updateSystem(system: HwdSysteem) {
    this.system.set(system);
  }

  public getMainboards(): MainboardType[] {
    return this._meta.mainBoards;
  }

  public chooseNumber() {
    let p = new Promise<number | undefined>((resolve, reject) => {
      const popupEl: NgElement & WithProperties<NumberPopupComponent> = document.createElement(
        'popup-element',
      ) as any;
      // Listen to the close event
      popupEl.addEventListener('closed', () => {
        document.body.removeChild(popupEl);
        resolve(undefined);
      });
      popupEl.addEventListener('save', (e) => {
        console.log(e);
        document.body.removeChild(popupEl);
        resolve((e as CustomEvent).detail as number);
      });
      // Set the message
      // Add to the DOM
      document.body.appendChild(popupEl);

    });

    return p;
  }


}
