import { Inject, Injectable } from '@angular/core';
import loadLibrary, { HeaderInfo, MainModule } from '@wasm/KopWeb_list';
import { ServerAPI } from '../serverapi';
import { saveLocalFiles } from '../utils/wasmVector';


@Injectable({
  providedIn: null
})
export class KopListService {

  constructor(@Inject('ServerApi') private electronAPI: ServerAPI) { }
  public wasmModule: MainModule | undefined;

  public async init(files: Record<string, any>) {
    if (this.wasmModule == undefined) {
      if (this.wasmModule == undefined) {
        let lib = await loadLibrary();
        this.wasmModule = lib;          
      }
      saveLocalFiles(files, this.wasmModule!);
    }
  }


  public async generateList(klantNo: string) {
    let wasm = this.wasmModule;
    let ret: Record<string, any> = {};
    if (wasm != undefined) {
      let info = wasm.FS.analyzePath('/working/LST', undefined);
      if (!info.exists) {
        wasm.FS.mkdir('/working/LST', undefined);
      }


      let hInfo = await this.electronAPI.getKlantInfo(klantNo);

      if (hInfo != undefined) {
        let lb = new wasm.ListBridge();

        lb.listSXB(klantNo, hInfo);
        let t = wasm.FS.readdir('/working/LST/');
        console.log(t);
        if (wasm.FS.analyzePath(`/working/LST/${klantNo}.LST`, undefined).exists) {
          let data = this.wasmModule!.FS.readFile(`/working/LST/${klantNo}.LST`, { encoding: 'binary' });
          ret[`/working/LST/${klantNo}.LST`] = data;          
        }
        if (wasm.FS.analyzePath(`/working/LST/${klantNo}.DBL`, undefined).exists) {
          let data = this.wasmModule!.FS.readFile(`/working/LST/${klantNo}.DBL`, { encoding: 'binary' });
          ret[`/working/LST/${klantNo}.DBL`] = data;
        }

      }
    }
    return ret;
  }
}
