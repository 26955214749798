<div class="detail-wrapper">
    @for (row of this.kanaalMap(); track $index; let i = $index) {
        <div class="kanaal-row">
            
            @for(cell of row; track $index; let j = $index) {
                @let kanaal = this.getKanaalForCell(i, j);
                @let isSelect = this.isSelected(i, j);
                @let label = this.kanaalLabel(kanaal);
                <kanaal-assignement [cell]="cell" [isSelected]="isSelect" [label]="label" (click)="this.onDetailClick(i, j)"></kanaal-assignement>
            }
        </div>
    }
</div>
