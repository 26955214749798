import { Component, computed, effect, input, output, signal } from '@angular/core';
import { KanaalSelection, KanaalSelectorBase } from '../kanaal-selector/KanaalSelectorBase';
import { AnalogIn, AnalogOut, DigitalIn, DigitalOut, IOType, RangedKanaal, RangedKanaalAssign } from '../../props/PropTypes';
import { HwdConfigService } from '../hwd-config.service';
import { HwdSysteem } from '../hdw-config/HdwSysteem';

@Component({
  selector: 'hdwv-kanaal-selector',
  templateUrl: './hdwv-selector.component.html',
  styleUrl: './hdwv-selector.component.scss'
})
export class HdwvSelectorComponent {

  constructor() {   }

  public kanaalType = input<IOType>();
  public range = input<number>(1);
  public assign = input.required<RangedKanaalAssign[]>();
  public system = input<HwdSysteem>();
  // public selection = signal<KanaalSelection>({});
  public onKanaalSelected = output<KanaalSelection>();

  public initialSelection = input<KanaalSelection>({});

  public kanaalMap = computed(() => {

    let ret: Record<string, Array<Array<RangedKanaalAssign>>> = {};

    let assignement = this.assign();
    for (let a of assignement) {
      for (let k = 0; k < a.rangedKnaal.range; k++) {
        if (a.rangedKnaal.start > 0) {
          let kanaal = a.rangedKnaal.start + k;
          let cell = this.getKanaalAssignement(ret, a.rangedKnaal.ioType, kanaal);
          cell.push(a);
        }
      }
    }

    return ret;
  });


  private getKanaalAssignement(ret: Record<string, Array<Array<RangedKanaalAssign>>>, ioType: IOType, kanaal: number) {
    let ioTypeStr = ioType.toString();
    if (ret[ioTypeStr] == undefined) {
      ret[ioTypeStr] = [];
    }
    if (ret[ioTypeStr][kanaal] == undefined) {
      ret[ioTypeStr][kanaal] = [];
    }
    return ret[ioTypeStr][kanaal];
  }

  protected isValidSelection(e: KanaalSelection) {
    let kanaal = e.kanaal?.start;
    if (kanaal == undefined) {
      return false;
    }
    let kType = this.kanaalType();
    if (kType != undefined && !kType.equals(e.kanaal?.ioType)) {
      return false;
    }
    if (kType?.equals(AnalogIn) && this.range() == 2) {
      return kanaal % 2 == 0;
    }
    return true;
  }

  onSelectionChange(e: KanaalSelection) {
    if (this.isValidSelection(e)) {
      this.onKanaalSelected.emit(e);
    }
  }


}
