import { Component, computed, effect, input, output, signal } from '@angular/core';
import { KopConfigService } from '../../core/kop-config.service';
import { JSBridgeService } from '../../core/jsbridge.service';
import { cKC_Servo } from '@wasm/KopWeb';
import { ModuleServo, ModuleParam, RangedKanaalInput, AnalogOut } from '../PropTypes';
import { wasmVectorToArray } from '../../utils/wasmVector';
import { KopModulesService } from '../../modules/kop-modules.service';
import { KanaalService } from '../../kanaal/kanaal.service';

@Component({
  selector: 'servo-editor',
  templateUrl: './servo-editor.component.html',
  styleUrl: './servo-editor.component.scss'
})
export class ServoEditorComponent {

  constructor(public kopConfigService: KopConfigService, public jsBridgeService: JSBridgeService, 
      public kopModulesService: KopModulesService, public kanaalService: KanaalService) {
    effect(() => {
      let initValue = this.initialValue();
      if (initValue != undefined) {
        this.editValue = {...initValue};
      }
    });
  }

  public editOnly = input<boolean>(false);
  public onlyDesc = input<boolean>(false);


  public isNew = input<boolean>(false);
  public isEditing = false;
  private _metaIdx = signal<number | undefined>(undefined);

  public metaIdx = input<number | undefined>(undefined);

  private newMetaIdx = computed(() => {
    if (this._metaIdx() != undefined) {
      return this._metaIdx();
    }
    return this.metaIdx();
  });
  // public servo = input<cKC_Servo>();
  public valueChanged  = output<ModuleServo>();

  public initialValue = input<ModuleServo>();

  public editValue: ModuleServo = {
    index: 0,
    uitgang: 0,
    inverted: false,
    looptijd: "00:00",
    modbusNetworkId: 0,
    soort: 0,
    stationnr: 0,
    params: {}
  };

  public onParamKanaalChange(event: RangedKanaalInput, pNo: number) {
    this.editValue.params[pNo].inverted = event.inverted == true;
    this.editValue.params[pNo].waarde = event.start;
  }

  public onKanaalInputChanged(event: RangedKanaalInput) {
    this.editValue.inverted = event.inverted == true;
    this.editValue.uitgang = event.start;
  }

  public newServoOptions = computed(() => {
    if (this.isNew()) {
      let servoMetas = this.kopConfigService.currentMeta().servos;
      let tmServos = this.kopConfigService.selectedAfd()?.afdeling().servos;
      if (tmServos != undefined) {
        let selectedModule = this.kopModulesService.selectedAfdModule();
        let ret = servoMetas.filter(sm => {
          if (selectedModule != undefined) {
            if (selectedModule.meta.allServos == undefined) {
              return false;
            }
            if (selectedModule.meta.allServos.indexOf(sm.index) < 0) {
              return false;
            }
          }
          return true;
        })
          .map(pm => { return { index: pm.index, label: pm.omschr.toString() } });
        return ret;
      }
    }
    return [];
  });

  alreadySelectedFN() {
    let tmServos = this.kopConfigService.selectedAfd()?.afdeling().servos;
    if (tmServos != undefined) {
      let servos = wasmVectorToArray(tmServos);
      let ret = (idx: number) => {
        return servos.find(a => a.index == idx) != undefined;
      }
      return ret;
    }
    return (idx: number) => false;
  }

  labelFn() {
    let ret = (meta: any) => (meta as any).label;
    return ret;
  }

  public modbusNetworks = computed(() => {
    let kopConfig = this.jsBridgeService.kopConfig();
    if (kopConfig != undefined) {
      let mb = wasmVectorToArray(kopConfig.gModBusNetworks);
      let ret = mb.map(m => {
        return {
          index: m.idNr,
          label: m.name.toString()
        }
      });
      ret.unshift({ index: 0, label: 'Geen' });
      return ret;
    }
    return [];
  });

  onNew(e: { index: number }) {
    let idx = e.index;
    this._metaIdx.set(idx);
    this.editValue = this.kopConfigService.buildDefaultServo(idx);
    this.isEditing = true;
  }


  public meta = computed(() => {
    let servo = this.initialValue();
    if (servo != undefined) {
      let meta = this.kopConfigService.currentMeta();
      return meta.servos.find(m => m.index == servo.index);
    }
    let chosenMeta = this.newMetaIdx();
    if (chosenMeta != undefined) {
      let meta = this.kopConfigService.currentMeta();
      return meta.servos.find(m => m.index == chosenMeta);
    }
  });

  public paramsMeta = computed(() => {
    let meta = this.meta();
    if (meta != undefined) {
      return wasmVectorToArray(meta.param);
    }
    return [];
  });

  servoParamTypeLabel(sp: ModuleParam) {
    let kanaalType = this.kanaalService.getKCParamType(sp);
    if (kanaalType == undefined) {
      return "Parameter";
    }
    return `${kanaalType?.ioType.type} ${kanaalType?.ioType.direction}`;
  }


  servoTypes() {
    return this.jsBridgeService.servoTypeName;
  }
  onEdit() {
    this.isEditing = true;
  }

  onDelete() {
    let servo = this.initialValue();
    this.kopConfigService.selectedAfd.update(afd => {
      if ((servo != undefined) && (afd != undefined)) {
        this.kopConfigService.eraseServo(afd, servo);
      }
      return afd;
    });

  }

  onSave() {
    this.isEditing = false;
    if (this.editOnly()) {
      this.valueChanged.emit(this.editValue);
      return;
    }
    let meta = this.meta();
    if (meta != undefined) {
      this.kopConfigService.upsertServo(this.editValue, meta);
    }

  }
}
