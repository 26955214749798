<kendo-dialog *ngIf="isEditing()" title="Download & Unlock" width="800px" (close)="this.onDone.emit(false)">
    <table>
        <tr>
            <th>File</th>
            <th>Last Modified</th>
        </tr>
        @for (f of this.filesToWatch; track f) {
            @let lastDate = this.getLastDate(f);
        <tr>
            <td>
                @if (lastDate != undefined) {
                    <a class="link" (click)="this.download(f)">{{f}}</a>
                } @else  {
                    {{f}}
                }
                
            </td>
            <td>{{lastDate != undefined ? lastDate : "N/A" }}</td>
        </tr>
        }
        <tr>
            <td colspan="2">
                <a class="link" (click)="this.downloadAll()">Download All</a>
            </td>
        </tr>
    </table>
    <kendo-textarea
                  placeholder="Opmerking..."
                  [rows]="3"
                  [(value)]="this.opmerking"
                  resizable="vertical"
                ></kendo-textarea>
    <button kendoButton *ngIf="this.canWrite" (click)="this.unlock()" [themeColor]="'primary'">Unlock</button>
    <pre>{{unlockResult}}</pre>
    <kendo-dialog-actions>
        <button kendoButton (click)="this.onDone.emit(false)">Close</button>
    </kendo-dialog-actions>
</kendo-dialog>