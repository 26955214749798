import { Component, computed, input } from '@angular/core';
import { HwdConfigService } from '../../hwd-config.service';
import { HwdSysteem, Rack } from '../../hdw-config/HdwSysteem';
import { isSysteemKaart, KaartType } from '../../hdw-config/hdw-konfig-types';
import { EditSystemService } from '../edit-system.service';

@Component({
  selector: 'rack-view',
  templateUrl: './rack-view.component.html',
  styleUrl: './rack-view.component.scss'
})
export class RackViewComponent {

  constructor(public hdwMetaService: HwdConfigService, public editSystem: EditSystemService) { }

  public rack = input.required<Rack>()

  public slotKaarten() {
    return this.rack().kaarten.filter(k => k.type.takesRackSpace);
  };

  async onKaartAdded(e: KaartType) {
    let nr = this.rack().getNextNumber();

    this.editSystem.system.update(_system => {
      if (_system != undefined) {
        _system.addKaart(e, nr, this.rack().id);        
      }
      return _system;      
    })
  }

}
