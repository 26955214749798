import type { RuntimeExports } from "@wasm/KopWeb";

type WasmVector<T> = {
    push_back(_0: T): void;
    resize(_0: number, _1: T): void;
    size(): number;
    get(_0: number): T | undefined;
    set(_0: number, _1: T): boolean;
    delete(): void;
}

type WasmVectorWA<T> = {
    push_back(_0: T): void;
    resize(_0: number, _1: T): void;
    size(): number;
    get(_0: number): T | undefined;
    at(_0: number): T;
    set(_0: number, _1: T): boolean;
    delete(): void;
}

type WasmVectorWithClear<T> = WasmVector<T> & {
    clear(): void;
}

interface WasmMap {
    size(): number;
    get(_0: number): any | undefined;
    set(_0: number, _1: any): void;
    keys(): WasmVector<number>;
    delete(): void;
}

type WasmMapWA<T> = {
    size(): number;
    get(_0: number): T | undefined;
    set(_0: number, _1: T): void;
    at(_0: number): T;
    keys(): WasmVector<number>;
    delete(): void;
}


export function eraseFromWasmVector<T extends { index: number }>(t: WasmVectorWithClear<T>, idx: number) {
    let old = wasmVectorToArray(t);
    t.clear();
    for (let ai of old) {
        if (ai.index != idx) {
            t.push_back(ai);
        }
    }
}

export function saveLocalFiles(files: Record<string, any>, wasmModule: typeof RuntimeExports) {
    for (let p of Object.entries(files)) {
        let fPath = p[0];
        let data = p[1];
        var stream = wasmModule.FS.open(fPath, 'w+', undefined);
        if (data != undefined) {
            wasmModule.FS.write(stream, data, 0, data.length, 0, undefined);
            wasmModule.FS.close(stream);
        }
    }
}

export function wasmVectorToArray<T>(t: WasmVector<T>): Array<T> {
    var ret: Array<T> = [];
    for (var i = 0; i < t.size(); i++) {
        ret.push(t.get(i)!);
    }
    return ret;
}

export function wasmVectorForEach<T>(t: WasmVectorWA<T>, lambda: (e: T, idx: number) => void) {
    for (var i = 0; i < t.size(); i++) {
        let a = t.at(i);
        lambda(a, i);
    }
}

export function wasmVectorFindIndex<T>(t: WasmVectorWA<T>, predicate: (e: T) => boolean): number {
    for (var i = 0; i < t.size(); i++) {
        let a = t.at(i);
        if (predicate(a)) {
            return i;
        }
    }
    return -1;

}

export function fillWasmVector<T>(dest: WasmVector<T>, source: Array<T>): WasmVector<T> {
    for (var idx of source) {
        dest.push_back(idx);
    }
    return dest;
}

export function wasmMapToRecord<TV>(t: WasmMapWA<any> | undefined, lambda: (e: any) => TV): Record<number, TV> {
    var ret: Record<number, TV> = {};
    if (t == undefined) {
        return ret;
    }
    wasmMapForEach(t, (k, v) => {
        ret[k] = lambda(v);
    })
    return ret;
}

export function wasmMapForEach<T>(t: WasmMapWA<T>, lambda: ( k: number, v: T) => void) {
    let keys = t.keys();
    for (let i = 0; i < keys.size(); i++) {
        let k = keys.get(i)!;
        let v = t.at(k);
        lambda(k, v);
    }   
    keys.delete();
}