@if (isNew()) {
    <div class="row">
        <meta-select-button 
            [availableMetas]="this.alarmsOptions()"
            [alreadySelected]="this.alreadySelectedFN()"
            (onSelect)="onNew($event)"
            [labelCb]="this.labelFn()" />
    </div>                
} @else {
    <div class="row normal-row parent-bck clickable" (click)="this.onEdit()">
        <pre class="pre">{{text()}}</pre>
    </div>    
}
<!-- <div>{{text()}}</div> -->
<kendo-dialog *ngIf="isEditing" title="Edit Alarm" width="300px" (close)="this.isEditing = false">
    <kendo-label text="Type">
        <kendo-dropdownlist [data]="this.types()" [valuePrimitive]="true" [(value)]="this.editVal.typeIndex"
            [valueField]="'index'" [textField]="'text'"></kendo-dropdownlist>
    </kendo-label>
    @let rType = {ioType: this.ioType, range: 1, start: this.editVal.kanaal, inverted: this.editVal.inverted};
    <kanaal-input [initialValue]="rType" (valueChanged)="this.onKanaalChange($event)"></kanaal-input>  

    <kendo-dialog-actions>
        <button kendoButton (click)="this.isEditing = false">Cancel</button>
        <button kendoButton (click)="onDelete()" themeColor="primary" *ngIf="!isNew()">
            Delete
        </button>
        <button kendoButton (click)="onSave()" themeColor="primary">
            Save
        </button>
    </kendo-dialog-actions>

</kendo-dialog>