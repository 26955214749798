import { Component, effect, input, output } from '@angular/core';
import { AnalogIn, AnalogOut, DigitalIn, DigitalOut, RangedKanaalAssign } from '../../props/PropTypes';
import { HwdSysteem } from '../hdw-config/HdwSysteem';
import { KSelectorPresentationType } from '../kanaal.service';
import { SelectEvent } from '@progress/kendo-angular-layout';

@Component({
  selector: 'kanaal-overview',
  templateUrl: './kanaal-overview.component.html',
  styles: ``
})
export class KanaalOverviewComponent {

  constructor() { 
    effect(() => {
      let sp = this.presentation();
      if (sp == 'hardware') {
        this.selectedTab = 4;
      } 
    });
  }


  public ai = AnalogIn;
  public ao = AnalogOut;
  public di = DigitalIn;
  public do = DigitalOut;

  public selectedTab = 0;
  
  public assign =  input.required<RangedKanaalAssign[]>();
  public system = input<HwdSysteem>();

  public presentation = input<KSelectorPresentationType>("compact");
  public presentationChanged = output<KSelectorPresentationType>();

  onTabSelect(e: SelectEvent) {
    this.selectedTab = e.index;
  } 

}
