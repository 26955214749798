import { effect, Injectable, signal } from '@angular/core';
import { HwdSysteem } from '../hdw-config/HdwSysteem';
import { HwdConfigService } from '../hwd-config.service';
import { init } from 'echarts';
import { MainboardType } from '../hdw-config/hdw-konfig-types';

@Injectable({
  providedIn: null
})
export class EditSystemService {

  constructor(public hdwMetaService: HwdConfigService) { 
    effect(() => {
      console.log("Edit System Service", this.system());
    })
  }

  public system = signal<HwdSysteem | undefined>(undefined, { equal: (a, b) => false });

  public startEditing() {
    this.system.set(undefined);
    let origSystem = this.hdwMetaService.system();
    if (origSystem != undefined) {
      let s = JSON.parse(JSON.stringify(origSystem.toSerializable()));
      this.system.set(new HwdSysteem(undefined, s));
    }
  }

  public addRack() {
    let _system = this.system();
    if (_system != undefined) {
      let meta = this.hdwMetaService.meta();
      let rack = meta.getRackByBus(_system.getBusTypes()[0])[0];
      this.system.update(s => {
        if (s != undefined) {
          s.addRack(rack);    
        }
        return s;
      });
    }
  }

  public init(m: MainboardType | undefined) {
    if (m == undefined) {
      this.system.set(undefined);
      return;
    }
    this.system.set(new HwdSysteem(m));
  }


}
