@if (isNew()) {
    @if (newServoOptions().length != 0) {
        <div class="row normal-row">
            <meta-select-button 
                [availableMetas]="this.newServoOptions()"
                [alreadySelected]="this.alreadySelectedFN()"
                (onSelect)="onNew($event)"
                [labelCb]="this.labelFn()" />
        </div>                
    }
} @else {
<div class="row normal-row parent-bck clickable" (click)="onEdit()">
    <div [title]="meta()?.index">{{meta()?.omschr}} 
        @if (!this.onlyDesc()) {
            : {{this.initialValue()?.uitgang}}{{this.initialValue()?.inverted ? "!" : ""}} 
        }
    </div>
</div>
}
<kendo-dialog *ngIf="isEditing" title="Edit Servo" width="700px" (close)="this.isEditing = false">
    <div>
        <fieldset :class="row split">
            <legend :class="legend">Output:</legend>

            <kendo-label text="Type">

                <kendo-dropdownlist [data]="servoTypes()" [textField]="'text'" [valueField]="'type'"
                    [valuePrimitive]="true" [(value)]="editValue.soort"/>
            </kendo-label>
            @if (editValue.soort == 6) {
                <kendo-label text="Node ID">
                    <kendo-numerictextbox [(value)]="editValue.stationnr" format="n0" [min]="0"
                        [style]="{ width: '70px' }"></kendo-numerictextbox>
                </kendo-label>
                <kendo-label text="ModBus Network">

                    <kendo-dropdownlist [data]="modbusNetworks()" [textField]="'label'" [valueField]="'index'"
                        [valuePrimitive]="true" [(value)]="editValue.modbusNetworkId" />
                </kendo-label>
    
            } @else {
                <!-- @let outputType = (editValue.soort == 5 || editValue.soort == 4) ? "Analog Uitgang" : "Digital Uitgang"; -->
                @if (editValue.soort == 1) {
                    <kendo-label text="Tijd">
                        <kendo-maskedtextbox [(value)]="editValue.looptijd" mask="00:00"
                            [style]="{ width: '100px' }"></kendo-maskedtextbox>
                    </kendo-label>
                        
                }
                @let kType = this.kanaalService.getServoKanaalType(this.editValue);
                @if (kType != undefined) {
                    @let rType = {
                        ioType: kType.ioType,
                        range: kType.range,
                        start: kType.start,
                        inverted: this.editValue.inverted
                    };
                    <kanaal-input [initialValue]="rType" (valueChanged)="onKanaalInputChanged($event)" [showTypeLabel]="true" ></kanaal-input>                            
                }
            }
        </fieldset>
    </div>
    <div>
        @for (paramMeta of paramsMeta(); track paramMeta.no) {
        <fieldset :class="cell" *ngIf="this.editValue.params[paramMeta.no] != undefined">
            <legend :class="legend">{{ paramMeta.omschr }} - {{servoParamTypeLabel(editValue.params[paramMeta.no]) }}</legend>
            <!-- <div *ngIf="this.kanaalService.getKCParamType(editValue.params[paramMeta.no]) != undefined">
                <kendo-checkbox #invertedChk
                    [(checkedState)]="editValue.params[paramMeta.no].inverted"></kendo-checkbox>
                <kendo-label [for]="invertedChk" text="Inverted">
                </kendo-label>
            </div> -->
            @let type = this.kanaalService.getKCParamType(editValue.params[paramMeta.no]);
            @if (type != undefined) {
                @let rType = {ioType: type.ioType, range: type.range, start: type.start, inverted: editValue.params[paramMeta.no].inverted};
                <kanaal-input [initialValue]="rType" (valueChanged)="this.onParamKanaalChange($event, paramMeta.no)"></kanaal-input>    
            } @else {
                <kendo-numerictextbox [(value)]="editValue.params[paramMeta.no].waarde" format="n0" [min]="0"
                [style]="{ width: '70px' }"></kendo-numerictextbox>
            }

        </fieldset>
        }
    </div>
    <kendo-dialog-actions>
        <button kendoButton (click)="this.isEditing = false">Cancel</button>
        <button kendoButton (click)="onDelete()" themeColor="primary" *ngIf="!isNew()">
            Delete
        </button>
        <button kendoButton (click)="onSave()" themeColor="primary">
            Save
        </button>
    </kendo-dialog-actions>
</kendo-dialog>
