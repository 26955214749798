import { computed, Injectable, signal } from '@angular/core';
import { KopConfigService } from '../core/kop-config.service';
import { JSBridgeService } from '../core/jsbridge.service';
import { KanaalService } from '../kanaal/kanaal.service';
import { KopModulesService } from '../modules/kop-modules.service';
import { cKC_Analog, cKC_BijzPrg, cKC_LosDef, cKC_LosDefs, cKC_Servo, cKC_Uren } from '@wasm/KopWeb';
import { DigitalIn, LosDefType, ModuleUren } from './PropTypes';
import { wasmMapToRecord, wasmVectorToArray } from '../utils/wasmVector';

export type PanelType = "AI" | "LosDef" | "Servo" | "DrogWand" | "Koeling" | "Verwarming" | "AlarmText" | "Alarms" | "BijzPrgs" | "UrenTeksten" | "Uren";

const panelPlace: Record<PanelType, number> = {
  AI: 1,
  AlarmText: 2,
  Alarms: 3,
  Servo: 4,
  LosDef: 5,
  BijzPrgs: 6,
  Koeling: 7,
  DrogWand: 8,
  UrenTeksten: 9,
  Uren: 10,
  Verwarming: 11
};


@Injectable({
  providedIn: null
})
export class PropsServiceService {

  constructor(public kopConfigService: KopConfigService, public jsBridgeService: JSBridgeService,
    public kopModulesService: KopModulesService, public kanaalService: KanaalService) { }



  public isKlassiekPresentation = signal<boolean>(true);

  private afdInfos = computed(() => {
    let afd = this.kopConfigService.selectedAfd()?.afdeling();
    if (afd != undefined) {
      let info = this.kopConfigService.currentMeta().afdelingInfos.find(ai => ai.type == afd.type.value);
      return info;
    }

  });

  public hasPanelType(panel: PanelType) {
    let place = panelPlace[panel];
    let info = this.afdInfos();
    if (info != undefined) {
      let hasD = info.contain.get(place);
      return hasD == 1;
    }
    return false;
  }


  public getLosDefsByType(base: cKC_LosDefs | undefined, type: LosDefType) {
    let tmLosDefs = base;
    let ret: Array<cKC_LosDef> = [];
    if (tmLosDefs != undefined) {
      let sv = wasmVectorToArray(tmLosDefs).filter(ld => ld.waarde > 0);
      let selectedModule = this.kopModulesService.selectedAfdModule();
      if (selectedModule == undefined) {
        ret = sv;
      } else {
        ret = sv.filter(mld => selectedModule.meta.allLosDefsIndexes != undefined && selectedModule.meta.allLosDefsIndexes.indexOf(mld.index) >= 0);
      }
    }
    return ret.map(s => this.kopConfigService.findOrBuildLosDef(s.index, type));

  }

  public losDefs = computed(() => {
    return this.getLosDefsByType(this.kopConfigService.selectedAfd()?.afdeling().losDefs, 'losdef');
  });

  public drogWands = computed(() => {
    return this.getLosDefsByType(this.kopConfigService.selectedAfd()?.afdeling().droogWand, 'droogwand');
  });

  public koelings = computed(() => {
    return this.getLosDefsByType(this.kopConfigService.selectedAfd()?.afdeling().koeling, 'koeling');
  });

  public verwarmings = computed(() => {
    return this.getLosDefsByType(this.kopConfigService.selectedAfd()?.afdeling().verwarming, 'verwarming');
  });


  public ais = computed(() => {
    let tmAIs = this.kopConfigService.selectedAfd()?.afdeling().analogs;
    let ret: Array<cKC_Analog> = [];
    if (tmAIs != undefined) {
      let aiv = wasmVectorToArray(tmAIs);
      let selectedModule = this.kopModulesService.selectedAfdModule();
      if (selectedModule == undefined) {
        ret = aiv;
      } else {
        ret = aiv.filter(ai => selectedModule.meta.allAnalogIngagen != undefined && selectedModule.meta.allAnalogIngagen.indexOf(ai.index) >= 0);
      }
    }
    return ret.map(ai => this.kopConfigService.aiKCtoModule(ai.index, ai));
  });

  public alarms = computed(() => {
    let tmAlarmsW = this.kopConfigService.selectedAfd()?.afdeling().alarms;
    if (tmAlarmsW != undefined) {
      let alarms = wasmVectorToArray(tmAlarmsW);
      return alarms;
    }
  });


  public servos = computed(() => {
    let tmServos = this.kopConfigService.selectedAfd()?.afdeling().servos;
    let ret: Array<cKC_Servo> = [];
    if (tmServos != undefined) {
      let sv = wasmVectorToArray(tmServos);
      let selectedModule = this.kopModulesService.selectedAfdModule();
      if (selectedModule == undefined) {
        ret = sv;
      } else {
        ret = sv.filter(servo => selectedModule.meta.allServos != undefined && selectedModule.meta.allServos.indexOf(servo.index) >= 0);
      }
    }
    return ret.map(s => this.kopConfigService.servoKCtoModule(s.index, s))
  });

  public bijzPrgs = computed(() => {
    let tmBPs = this.kopConfigService.selectedAfd()?.afdeling().bijzPrgs;
    let ret: Array<cKC_BijzPrg> = [];
    if (tmBPs != undefined) {
      ret = wasmVectorToArray(tmBPs);
    }
    return ret.map(bp => this.kopConfigService.bijzPrgmKCtoModule(bp.index, bp));
  });

  public uren = computed(() => {
    let tmUrenW = this.kopConfigService.selectedAfd()?.afdeling().urens;
    if (tmUrenW != undefined) {
      let ret = wasmMapToRecord(tmUrenW, e => e as cKC_Uren);
      return ret;
    }
  });

  public urenKeys = computed(() => {
    let u = this.uren();
    if (u == undefined) return [];
    return Object.keys(u).map(k => parseInt(k));
  });


  public urenModule = computed(() => {
    let ckUren = this.uren();
    if (ckUren != undefined) {

      let tmp = Object.entries(ckUren).map(([k, v]) => {
        let type = DigitalIn;
        let inv = this.kanaalService.getInvTab(v.kanaal, type);
        let ret: ModuleUren ={
          key: parseInt(k),
          blank: v.blank,
          kanaal: v.kanaal,
          no: v.no,
          type: v.type,
          inverted: inv
        }
        return [k,ret];
      });
      console.log(tmp);
      return Object.fromEntries(tmp);
    }
  });


}
