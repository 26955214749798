import { Component, computed, effect, input } from '@angular/core';
import { KopConfigService } from '../../core/kop-config.service';
import { JSBridgeService } from '../../core/jsbridge.service';
import { KopModulesService } from '../../modules/kop-modules.service';
import { Alarm, cKC_Alarm } from '@wasm/KopWeb';
import { eraseFromWasmVector, wasmMapToRecord, wasmVectorFindIndex, wasmVectorToArray } from '../../utils/wasmVector';
import { AnalogIn, DigitalIn, ModuleAlarm, RangedKanaal, RangedKanaalInput } from '../PropTypes';
import { KanaalService } from '../../kanaal/kanaal.service';

@Component({
  selector: 'alarm-editor',
  templateUrl: './alarm-editor.component.html',
  styles: ``
})
export class AlarmEditorComponent {

  constructor(public kopConfigService: KopConfigService, public jsBridgeService: JSBridgeService,
    public kopModulesService: KopModulesService, public kanaalService: KanaalService) {
    effect(() => {
      let al = this.initialValue();
      if (al != undefined) {
        this.editVal = { ...al };
        return;
      }
      let alarm = this.alarm();
      if (alarm != undefined) {
        this.editVal = this.kopConfigService.kcAlarm2Model(alarm);
      }
    })
  }


  public alarm = input<cKC_Alarm>()
  public isNew = input<boolean>(false);
  public newMeta: Alarm | undefined = undefined;
  public initialValue = input<ModuleAlarm>();
  public onlyDesc = input<boolean>(false);

  public text() {
    let typeIndex = 0;
    let kanaal = 0;
    let iv = this.initialValue();
    if (iv != undefined) {
      typeIndex = iv.typeIndex;
      kanaal = iv.kanaal;
    } else {
      let a = this.alarm();
      if (a != undefined) {
        typeIndex = a.typeIndex;
        kanaal = a.kanaal;
      }
    }
    let meta = this.meta();
    let jsBridge = this.jsBridgeService.getBridgeSync();

    if (meta != undefined) {
      let vrijText = this.kopConfigService.getTextForAlarm(this.meta())
      let alarmTypes = wasmVectorToArray(jsBridge.getAlarmTypes());
      let type = alarmTypes.find(at => at.index == typeIndex);
      if (type == undefined) return "";
      let inv = this.kanaalService.getInvTab(kanaal, DigitalIn);
      if (this.onlyDesc()) {
        return vrijText; 
      }
      return `${vrijText} - ${type.omschr} - ${kanaal}${inv ? '!' : ''}`;
    }
    return "";
  }


  public meta = computed(() => {
    let a = this.alarm();
    if (a != undefined) {
      return this.kopConfigService.getAlarmMeta(a);
    }
    let iv = this.initialValue();
    if (iv != undefined) {
      return this.kopConfigService.getAlarmMeta(iv);
    }
    return this.newMeta;
  });

  public alarmsOptions = computed(() => {
    let metas = this.kopConfigService.getAlarmsMeta();
    return metas;
  });

  labelFn() {
    let ret = (meta: any) => {
      let ret2 = this.kopConfigService.getTextForAlarm(meta);
      return ret2;
    }
    return ret;
  }

  onNew(a: any) {
    let alarm = a as Alarm;
    this.newMeta = alarm;
    this.editVal = {
      index: alarm.index,
      kanaal: 0,
      typeIndex: this.types()[0].index,
      inverted: false
    }
    this.isEditing = true;
  }

  alreadySelectedFN() {
    let tmAlarms = this.kopConfigService.selectedAfd()?.afdeling().alarms;
    if (tmAlarms != undefined) {
      // let alarms = wasmVectorToArray(tmAlarms);
      let ret = (idx: number) => {
        return wasmVectorFindIndex(tmAlarms, a => a.index == idx) != -1;
        // return alarms.find(a => a.index == idx) != undefined;
      }
      return ret;
    }
    return (idx: number) => false;
  }

  public types = computed(() => {
    let jsBridge = this.jsBridgeService.getBridgeSync();
    let meta = this.meta();
    let alarmTypes = wasmVectorToArray(jsBridge.getAlarmTypes()).filter(at => at.groep == meta?.groep);
    return alarmTypes.map(at => {
      return {
        index: at.index,
        text: at.omschr
      }
    });
  });


  public isEditing = false;
  public editVal: ModuleAlarm = {
    index: 0,
    kanaal: 0,
    typeIndex: 0,
    inverted: false
  };

  public ioType = DigitalIn;



  onEdit() {
    // let alarm = this.alarm();
    // if (alarm == undefined) return;
    // this.editVal = this.kopConfigService.kcAlarm2Model(alarm);
    this.isEditing = true;
  }


  onKanaalChange(event: RangedKanaalInput) {
    this.editVal.kanaal = event.start;
    this.editVal.inverted = event.inverted == true;
  }
  onDelete() {
    this.kopConfigService.selectedAfd.update(afd => {
      let alarmsW = afd?.afdeling().alarms;
      if (alarmsW != undefined) {
        eraseFromWasmVector(alarmsW, this.editVal.index);
      }
      return afd;
    });
  }
  onSave() {
    this.kopConfigService.selectedAfd.update(afd => {
      this.kanaalService.setInvTab(this.editVal.kanaal, DigitalIn, this.editVal.inverted);
      let alarms = afd?.afdeling().alarms;
      if (alarms != undefined) {
        let idx = wasmVectorToArray(alarms).findIndex(al => al.index == this.editVal.index);
        let alarmRef: cKC_Alarm | undefined;
        if (idx >= 0) {
          alarmRef = alarms.at(idx);
        } else {
          let wasm = this.jsBridgeService.wasmModule!;
          alarmRef = new wasm.cKC_Alarm();
        }
        if (alarmRef != undefined) {
          alarmRef.index = this.editVal.index;
          alarmRef.kanaal = this.editVal.kanaal;
          alarmRef.typeIndex = this.editVal.typeIndex;
        }
        if (idx < 0) {
          alarms.push_back(alarmRef);
        }
      }

      return afd;
    });
    this.isEditing = false;
  }
}
