import { Component, effect, signal } from '@angular/core';
import { HwdSysteem } from '../../hdw-config/HdwSysteem';
import { HwdConfigService } from '../../hwd-config.service';
import { MainboardType } from '../../hdw-config/hdw-konfig-types';
import { EditSystemService } from '../edit-system.service';
import { DialogCloseResult, DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'system-def',
  templateUrl: './system-def.component.html',
  styleUrl: './system-def.component.scss',
  providers: [EditSystemService]
})
export class SystemDefComponent {

  constructor(public hdwMetaService: HwdConfigService, public editSystem: EditSystemService, private dialogService: DialogService) { 
  }

  public isEditing = false;


  public startEditing() {
    this.isEditing = true;

    this.editSystem.startEditing();
  }

  public addRack() {
    this.editSystem.addRack();
  }

  public onMainboard(e: MainboardType | undefined) {
    this.editSystem.init(e);
  }

  public async save() {
    let _system = this.editSystem.system();
    if (_system != undefined) {
      let updates = this.hdwMetaService.getUpdateSet(_system);
      if (updates.length > 0) {
        const dialog: DialogRef = this.dialogService.open({
          title: "I/O Opniew Benoemen:",
          content: `Nummering van bestande I/O kaarten is veranderd! 
                Wil je de I/O automatisch hernoemen zodat ze bij dezelfde kaart blijven horen?`,
          actions: [{ text: "Nee", id: "no" }, { text: "Ja", id:"yes", themeColor: "primary" }],
          preventAction: (ev) => {
            return ev instanceof DialogCloseResult;
          },
        });

        let result = (await firstValueFrom(dialog.result)) as any;
        if (result.id == "yes") {
          this.hdwMetaService.applyUpdates(updates);
        }
      } 
      this.hdwMetaService.updateSystem(_system);
      
    }
    this.isEditing = false;
  }

}
