import { Component, effect, input, output, signal } from '@angular/core';
import { AnalogIn, IOType, RangedKanaal, RangedKanaalAssign } from '../../props/PropTypes';
import { KanaalSelection } from '../kanaal-selector/KanaalSelectorBase';
import { rangedKanaalLabel } from '../kanaal.utils';

@Component({
  selector: 'hdw-io-selector',
  templateUrl: './hdw-io-selector.component.html',
  styleUrl: './hdw-io-selector.component.scss'
})
export class HdwIoSelectorComponent {

  constructor() { 
    // effect(() => {
    //   console.log(this.initialSelection());
    // });
  }

  public kanaalMap = input.required<Record<string, Array<Array<RangedKanaalAssign>>>>();
  public initialSelection = input<KanaalSelection>({});

  public ios = input.required<RangedKanaal[]>();
  public selection = signal<KanaalSelection>({});
  public presentation = input<"horizontal" | "vertical">("vertical");
  public onKanaalSelected = output<KanaalSelection>();

  public isKanaalSelected(rkanaal: RangedKanaal) {
    let selection = this.selection();
    let sKanaal = selection.kanaal?.start;
    let range = selection.kanaal?.range;
    let ioType = selection.kanaal?.ioType;
    if (sKanaal == undefined) {
      sKanaal = this.initialSelection().kanaal?.start;
    }
    if (range == undefined) {
      range = this.initialSelection().kanaal?.range;
    }
    if (ioType == undefined) {
      ioType = this.initialSelection().kanaal?.ioType;
    }
    if (sKanaal == undefined || range == undefined) {
      return false;
    }
    if (!rkanaal.ioType.equals(ioType)) {
      return false;
    }
    let kanaal = rkanaal.start;
    return sKanaal <= kanaal && kanaal < sKanaal + range;
  }

  public isSingleKanaalSelected(kanaal: number, ioType: IOType) {
    let ret =  this.isKanaalSelected({ ioType: ioType, range: 1, start: kanaal});
    // console.log(`Is Selected ${kanaal} ${ioType}: ${ret}`);  
    return ret;
  }

  public getAssignment(ioType: IOType, kanaal: number): Array<RangedKanaalAssign> {
    let ioTypeStr = ioType.toString();
    if (this.kanaalMap()[ioTypeStr] == undefined) {
      return [];
    }
    if (this.kanaalMap()[ioTypeStr][kanaal] == undefined) {
      return [];
    }
    return this.kanaalMap()[ioTypeStr][kanaal];
  }

  public generateRange(start: number, size: number) {
    let ret = [];
    for (let i = 0; i < size; i++) {
      ret.push(start + i);
    }
    return ret;
  }

  public onDetailClick(kanaal: number, ioType: IOType) {
    let initialRange = this.initialSelection().kanaal?.range;
    let assignement = this.getAssignment(ioType, kanaal);
    this.onKanaalSelected.emit({ 
      kanaal: {
        ioType: ioType,
        range: initialRange != undefined ? initialRange : 1,
        start: kanaal
      },
      assignement: assignement
    });
  }


  public kanaalLabel(kanaal: number, ioType: IOType): string {
    let kNumber =  rangedKanaalLabel({
        ioType: ioType,
        range: 1,
        start: kanaal
    }).split(": ")[1];
    if (ioType.equals(AnalogIn) && kanaal % 2 == 1) {
      return "a";
    }
    return ioType.toString() + ":" + kNumber;
}

}
