import { AnalogIn, AnalogOut, DigitalIn, DigitalOut, IOType } from "../../props/PropTypes";
import { BusManagerBase } from "./BusManagerBase";
import { HdwRackType, isCombiKaart, isSimpleKaart, isSysteemKaart, KaartType } from "./hdw-konfig-types";
import { BusManager, HwdSysteem, Kaart, Rack } from "./HdwSysteem";

const findLastIndex = function (a: any[], predicate: (v: any) => boolean) {
    for (let i = a.length - 1; i >= 0; i--) {
        if (predicate(a[i])) { 
            return i; 
        }
    } 
    return -1;
};

export class FastLinkBusManager extends BusManagerBase implements BusManager{
    constructor(systeem: HwdSysteem) {
        super(systeem, "fastlink");
    }
    deleteKaart(id: number): void {
        super.deleteKaart(id);
        this.assignOffsets();
    }
    addRack(rackType: HdwRackType): number {
        let id = super.addRack(rackType);
        let rack = this.getRack(id);
        if (rack != undefined && rackType.karten.length > 0) {
            let nr = this.getRacks().length
            let kaart = new Kaart(rackType.karten[0], 1, this.systeem.getNextKaartId(), nr);
            rack.addKaart(kaart);
        }
        this.assignOffsets();
        return id;

    }


    addKaart(kaartType: KaartType, number: number, rackId: number): number {
        let rack = this.getRack(rackId);
        if (rack != undefined) {
            let id = this.systeem.getNextKaartId();
            let kaart = new Kaart(kaartType, number, id);
            rack.addKaart(kaart);
            this.assignOffsets();
            return id;
        }
        return -1;
    }

    public sortRack(rack: Rack) {
        rack.kaarten.sort((kaart1, kaart2) => {
            if (!kaart1.type.takesRackSpace) {
                return -1;
            } else if (!kaart2.type.takesRackSpace) {
                return 1;
            }
            return kaart1.number - kaart2.number
        });

    }

    public sortRacks(racks: Rack[]) {
        racks.sort((r1, r2) => {
            let x1 = r1.kaarten.findIndex(this.xtPredicate);
            let x2 = r2.kaarten.findIndex(this.xtPredicate);
            if (x1 == -1) {
                return 1;
            } else if (x2 == -1) {
                return -1;
            }

            return r1.kaarten[x1].dipSwitch - r2.kaarten[x2].dipSwitch;
        });
        for (let rack of racks) {
            this.sortRack(rack);
        }
    }

    public assignOffsets() {
        let racks = this.systeem.getRacks("fastlink");
        //Sort the racks by the number of the XTender card
        this.sortRacks(racks);

        this.assignOffsetsFor(AnalogIn);
        this.assignOffsetsFor(AnalogOut);
        this.assignOffsetsFor(DigitalIn);
        this.assignOffsetsFor(DigitalOut);
    }

    private xtPredicate = (k: Kaart) => {
        if (isSysteemKaart(k.type)) {
            return true;
        }
        return false;
    }


    private addKarts(dest: Kaart[], src: Kaart[], ioType: IOType) {
        for (let kaart of src) {
            if (isSimpleKaart(kaart.type) && new IOType(kaart.type.ioType).equals(new IOType(ioType))) {
                dest.push(kaart);
            }
            if (isCombiKaart(kaart.type)) {
                dest.push(kaart);
            }
        }
    }

    public assignOffsetsFor(ioType: IOType) {
        let cards: Kaart[] = [];
        let racks = this.systeem.getRacks("fastlink");
        let mainRack = racks[0];
        let masterXTIdx = findLastIndex(mainRack.kaarten, this.xtPredicate);
        if (masterXTIdx == -1) {
            return;
        }
        let masterXT = mainRack.kaarten[masterXTIdx];
        if (masterXT.type.takesRackSpace == false) {
            this.addKarts(cards, mainRack.kaarten, ioType);
        } else {
            this.addKarts(cards, mainRack.kaarten.slice(0, masterXTIdx), ioType);
            for (let rack of racks.slice(1)) {
                this.addKarts(cards, rack.kaarten, ioType);
            }
            this.addKarts(cards, mainRack.kaarten.slice(masterXTIdx), ioType);
        }
        let startOffset = 0;
        if (ioType.equals(AnalogIn)) {
            startOffset = 2;
        } else {
            startOffset = 1;
        }
        for (let kaart of cards) {
            kaart.setOffset(startOffset, ioType);
            startOffset += kaart.getReservedIOS(ioType);
        }
    }

}