import { Component, computed, Inject, signal } from '@angular/core';
import { KopModulesService } from '../../modules/kop-modules.service';
import { KanaalService } from '../../kanaal/kanaal.service';
import { JSBridgeService } from '../jsbridge.service';
import { ServerAPI } from '../../serverapi';
import { KopConfigService } from '../kop-config.service';


@Component({
  selector: 'app-bar',
  templateUrl: './app-bar.component.html',
  styles: `
  .appbar {
    background-color: var(--dark-green);
  }
  `
})
export class AppBarComponent {

  constructor(public kopModulesService: KopModulesService, public jsBridgeService: JSBridgeService, public kanaalService: KanaalService,
    @Inject('ServerApi') private electronAPI: ServerAPI, private kopConfigService: KopConfigService
  ) { 
    electronAPI.canWriteKlant(jsBridgeService.getKlantNo()).then(canWrite => {
      if (!canWrite) {
        this.fileOptions[0].disabled = true;
      }
    });
  }

  onSave() {
    this.kopModulesService.save();
  }

  public editVrijAlarmen = signal(false);
  public editUren = signal(false);
  public editGlobalConfig = signal(false);
  public editGlobalConfigExtra = signal(false);
  public editModbus = signal(false);
  public generateList = signal(false);
  public unlock = signal(false);

  public configOptions = [{
    text: "Configuratie",
    click: () => {
      this.editGlobalConfig.set(true);
    }
  }, {
    text: "Extra Configuratie",
    click: () => {
      this.editGlobalConfigExtra.set(true);
    }
  }, {
    text: "Vrije Alarm Teksten",
    click: () => {
      this.editVrijAlarmen.set(true);
    }
  },
  {
    text: "Urentellers & Speciale Teksten",
    click: () => {
      this.editUren.set(true);
    }
  },
  {
    text: "Modbus Netwerken",
    click: () => {
      this.editModbus.set(true);
    }
  },
  ];

  public fileOptions = [{
      text: "Opslaan",
      disabled: false,
      click: () => {
        this.onSave()
      }
    },
    {
      text: "XML Vergelijken",
      click: () => {
        this.showXmlCompare.set(true)
      }
    },
    {
      text: "Lijsten genereren",
      click: () => {
        this.generateList.set(true)
      }
    },
    {
      text: "Unlock & Download",
      click: () => {
        this.unlock.set(true)
      }
    }

  ]


  public showKanaalOverview = signal(false);
  public showXmlCompare = signal(false);

  public assign = computed(() => {
    this.kopConfigService.afdelings();
    this.kopConfigService.selectedAfd();
    let kfg = this.jsBridgeService.getBridgeSync().getConfig();
    let assignement = this.kanaalService.getKonfigKanalen(kfg);
    return assignement;
  });


  public async compile() {
    this.jsBridgeService.getBridgeSync().save();

    await this.jsBridgeService.compileBin();
    await this.jsBridgeService.compilePCIBBin();
  }

}
