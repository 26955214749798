import { Injectable } from '@angular/core';
import { JSBridgeService } from './jsbridge.service';
import { wasmVectorToArray } from '../utils/wasmVector';


export type MenuItem = {
  omschr: string,
  value: number,
  mask: number,
  submenu: number,
  kort: string,
  gmask: number
}

@Injectable({
  providedIn: null
})
export class LDMenuService {

  constructor(private jsBridgeService: JSBridgeService) {

  }

  private cache: Record<number, MenuItem[]> = {};
  _getMenu(menunr: number) {
    let items = this.jsBridgeService.getBridgeSync().getMenuItems(menunr);
    var ret:Array<MenuItem> = [];
    for (var i = 0; i < items.size(); i++) {
        let item = items.get(i)!;
        ret.push({
          gmask: item.gmask,
          kort: item.kort.toString(),
          omschr: item.omschr.toString(),
          mask: item.mask,
          submenu: item.submenu,
          value: item.value
        });
        
    }
    items.delete();
    return ret;

    //return wasmVectorToArray(items);
  }

  getMenu(menunr: number) {
    if (!this.cache[menunr]) {
      this.cache[menunr] = this._getMenu(menunr);
    }
    return this.cache[menunr];
  }

  valueLabel(menunr: number | undefined, waarde: number | undefined) {
    if ((menunr == undefined) || (waarde == undefined)) {
      return "";
    }
    let items = this.getMenu(menunr);
    if (waarde != 0) {
      let used = items.filter(item => {
        let a = waarde & item.mask;
        let ret = (a == item.value);
        return ret;

      });
      return used.map(item => item.kort).join(",");
    }
    return "";
  }

}
