import { Injectable } from '@angular/core';
import { CreateTRPCProxyClient, createTRPCProxyClient, httpBatchLink } from '@trpc/client';
import type { AppRouter } from '@server/src';
import { ServerAPI } from 'kopweb-core';
import * as base64 from "byte-base64";
import { AuthService } from '@auth0/auth0-angular';
import { HeaderInfo } from '@wasm/KopWeb_list';
import { parse, stringify } from "devalue";


@Injectable({
  providedIn: 'root'
})
export class TrpcAPIService implements ServerAPI {

  constructor(public auth: AuthService) {
    auth.user$.subscribe(user => {
      if (user != undefined) {
        let dsc400 = user['https://DSC400.nl'];
        if (dsc400 != undefined && dsc400['jwt_koppen'] != undefined) {
          this.token = dsc400['jwt_koppen'];
        }
      }
    });
  }

  async canWriteKlant(klantNo: string): Promise<boolean> {
    let ret = await this.client.canWriteKlant.query(klantNo);
    return ret;
  }
  async getFileInfos(files: string[]): Promise<Record<string, Date>> {
    let ret = await this.client.getFileInfos.query(files);
    return ret;
  }
  async getKlantInfo(klantNo: string): Promise<HeaderInfo | undefined> {
    let ret = await this.client.getKopInfo.query(klantNo);
    return ret;
  }
  async unlock(fileName: string, info: {
    klantNo: string,
    opmerking: string
  }) {
    let ret = await this.client.unlock.mutate({ fileName, klantNo: info.klantNo, opmerking: info.opmerking });
    return ret;
  }
  isElectron(): boolean {
    return false;
  }
  getKlantNo(): string {
    return "ZZ0000";
  }

  private token: string = "";

  privateCreateClient() {
    let d = this;

    let ret = createTRPCProxyClient<AppRouter>({
      transformer: {
        input: {
          serialize: (object) => stringify(object),
          deserialize: (object) => parse(object),
        },
        output: {
          serialize: (object) => stringify(object),
          deserialize: (object) => parse(object),
        },
      },
      links: [
        httpBatchLink({
          url: window.location.origin + '/trpc',
          // You can pass any HTTP headers you wish here
          headers() {
            return {
              authorization: d.token
            };
          }
        }),
      ],
    });

    return ret;
  }

  public client: CreateTRPCProxyClient<AppRouter> = this.privateCreateClient();

  async getFile(fileName: string) {
    let b64 = await this.client.getFile.query(fileName);
    let b8 = base64.base64ToBytes(b64);
    return b8;
  }
  async getAsset(fileName: string) {
    let b64 = await this.client.getAsset.query(fileName);
    if (b64 == undefined) {
      return undefined;
    }
    let b8 = base64.base64ToBytes(b64);
    return b8;
  }
  async saveFile(fileName: string, data: Uint8Array): Promise<void> {
    let b64 = base64.bytesToBase64(data);

    await this.client.saveFile.mutate({ fileName, data: b64 });
  }

  async getConfigs(): Promise<any> {
    let configs = await this.client.getConfigs.query();
    return configs;
  }


}
