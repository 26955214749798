import { Component, output } from '@angular/core';

@Component({
  selector: 'key-wrapper',
  templateUrl: './key-wrapper.component.html',
  styles: `
    :host:focus-visible {
      outline: none;
    }`,
  host: {
    '[tabindex]' : '0',
    '(keydown)': 'updateValue($event)',
  }
})
export class KeyWrapperComponent {

  public onEsc = output<KeyboardEvent>();
  updateValue(event: KeyboardEvent) {
    console.log(event);
    if (event.key == "Escape") {
      this.onEsc.emit(event);
    }
  }

}
