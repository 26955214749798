<div >

    @let label = this.showTypeLabel() ? this.ioType.toString() : "Kanaal";
    <kendo-label [text]="label" class="kanaal-input">

        <kendo-numerictextbox #kanaalTextBox [value]="this.start" [min]="0" (valueChange)="onKanaalChange($event)"
            format="n0" [style]="{ width: '100px' }"></kendo-numerictextbox>
        
        @if (canChoose()) {
            <button kendoButton (click)="this.showSelector = !this.showSelector">Kies</button>
        }
        @if (this.hasAKanaal() && !this.virtualAI()) {
        <kendo-checkbox #isACheckbox [checkedState]="this.isA"
            (checkedStateChange)="onAKanaalChange($event)"></kendo-checkbox>
        <kendo-label [for]="isACheckbox" text="A Kanaal">
        </kendo-label>
        }
        @if (this.hasInverted()) {
        <kendo-checkbox #invertedChk [checkedState]="this.inverted" style="align-self: center;"
            (checkedStateChange)="onInvertedChange($event)"></kendo-checkbox>
        <kendo-label [for]="invertedChk" text="Inverted">
        </kendo-label>
        }
    </kendo-label>


</div>

<kendo-dialog *ngIf="this.showSelector" [title]="this.ioType.toString()" width="1300px"
    (close)="this.showSelector = false">
    <div>
        <kanaal-selector [kanaalType]="this.ioType" (onKanaalSelected)="onKanaalSelected($event)"
            [initialPresentation]="this.kanaalService.prefferedPresentation()"
            (presentationChanged)="this.kanaalService.prefferedPresentation.set($event)"
            [initialSelection]="this.kanaalSelection" [range]="this.range" [assign]="this.assign()" [system]="this.hdwConfigService.system()"></kanaal-selector>
    </div>
    <kendo-dialog-actions>
        <button kendoButton (click)="this.showSelector = false">Close</button>
        <button kendoButton (click)="this.onOK()" themeColor="primary">Select</button>
    </kendo-dialog-actions>
</kendo-dialog>