import { Component, effect, Inject, input, output, signal } from '@angular/core';
import { JSBridgeService } from '../jsbridge.service';
import { ServerAPI } from '../../serverapi';
import { KopModulesService } from '../../modules/kop-modules.service';
import { format } from "date-fns";
import {Zippable, zipSync} from 'fflate';

@Component({
  selector: 'unlock',
  templateUrl: './unlock.component.html',
  styleUrl: './unlock.component.scss'
})
export class UnlockComponent {

  public filesToWatch: string[] = [];
  public fileInfos: Record<string, Date> = {};

  constructor(public jsBridgeService: JSBridgeService, @Inject('ServerApi') private serverApi: ServerAPI, public kopModulesService: KopModulesService) {
    effect(async () => {
      if (this.isEditing()) {
        await this.getFileInfos();
        this.canWrite = await serverApi.canWriteKlant(jsBridgeService.getKlantNo());
      }
    });
  }

  public isEditing = input<boolean>(false);
  public onDone = output<boolean>();
  public opmerking = signal('');
  public unlockResult = '';

  private async getFileInfos() {
    let klantNo = this.jsBridgeService.getKlantNo();
    this.filesToWatch = [`SXB/${klantNo}.SXB`, `HEX/${klantNo}.HEX`, `PCI/${klantNo}.BIN`, `LST/${klantNo}.LST`];
    this.fileInfos = await this.serverApi.getFileInfos(this.filesToWatch);

  }


  public getLastDate(f: string) {
    if (this.fileInfos[f] != undefined) {
      let dt = this.fileInfos[f];
      return format(dt, 'Pp (OOO)');
    }
    return undefined;
  }

  public canWrite: boolean = false;

  public async download(f: string) {
    let data = await this.serverApi.getFile(f);
    if (data != undefined) {
      let blob = new Blob([data], { type: 'application/octet-stream' });
      let url = URL.createObjectURL(blob);
      let a = document.createElement('a');
      a.href = url;
      let parts = f.split('/');

      a.download = parts[parts.length - 1];
      a.click();
    }
  }

  public async downloadAll() {
    let files: Zippable = {};
    for (let f of this.filesToWatch) {
      let data = await this.serverApi.getFile(f);
      if ((data != undefined) && (data.length > 0)) {
        files[f] = [data, {level: 2}];
      }
    }
    let zip = zipSync(files);
    let klantNo = this.jsBridgeService.getKlantNo();

    let blob = new Blob([zip], { type: 'application/octet-stream' });
    let url = URL.createObjectURL(blob);
    let a = document.createElement('a');
    a.href = url;

    a.download = `${klantNo}.zip`;
    a.click();
  
  }

  public async unlock() {
    this.unlockResult = "Unlocking .....";
    let klantNo = this.jsBridgeService.getKlantNo();
    await this.kopModulesService.save();
    await this.jsBridgeService.compileBin();
    await this.jsBridgeService.compilePCIBBin();
    await this.jsBridgeService.generateKopList();
    let result = await this.serverApi.unlock(`SXB/${klantNo}.SXB`, {
      klantNo: klantNo,
      opmerking: this.opmerking()

    });
    if (result?.success) {
      await this.jsBridgeService.unlock();
      if (result.volgNummer != undefined) {
        this.jsBridgeService.updateVolgNummer(result.volgNummer);
        await this.kopModulesService.save();
        await this.jsBridgeService.saveFile(`HEX/${klantNo}.HEX`);
        await this.jsBridgeService.saveFile(`BIN/${klantNo}.BEN`);
        await this.jsBridgeService.saveFile(`PCI/${klantNo}.BIN`);
        await this.jsBridgeService.saveFile(`LST/${klantNo}.LST`);
        await this.jsBridgeService.saveFile(`LST/${klantNo}.DBL`);
      }
    }
    this.unlockResult = JSON.stringify(result, undefined, 2);
    await this.getFileInfos();


  }
}
