<kendo-tabstrip [animate]="false" (tabSelect)="this.onTabSelect($event)">
  <kendo-tabstrip-tab title="Analoog In" [selected]="this.selectedTab == 0">
    <ng-template kendoTabContent>
      <kanaal-selector [kanaalType]="this.ai" [assign]="this.assign()" [system]="this.system()"
        (presentationChanged)="this.presentationChanged.emit($event)"
        [initialPresentation]="this.presentation() != 'hardware' ? this.presentation() : 'compact'"
        [limitPresentations]="['compact', 'detail']"></kanaal-selector>
    </ng-template>
  </kendo-tabstrip-tab>
  <kendo-tabstrip-tab title="Analoog Uit" [selected]="this.selectedTab == 1">
    <ng-template kendoTabContent>
      <kanaal-selector [kanaalType]="this.ao" [assign]="this.assign()" [system]="this.system()"
        (presentationChanged)="this.presentationChanged.emit($event)"
        [initialPresentation]="this.presentation() != 'hardware' ? this.presentation() : 'compact'"
        [limitPresentations]="['compact', 'detail']"></kanaal-selector>
    </ng-template>
  </kendo-tabstrip-tab>
  <kendo-tabstrip-tab title="Digitaal In" [selected]="this.selectedTab == 2">
    <ng-template kendoTabContent>
      <kanaal-selector [kanaalType]="this.di" [assign]="this.assign()" [system]="this.system()"
        (presentationChanged)="this.presentationChanged.emit($event)"
        [initialPresentation]="this.presentation() != 'hardware' ? this.presentation() : 'compact'"
        [limitPresentations]="['compact', 'detail']"></kanaal-selector>
    </ng-template>
  </kendo-tabstrip-tab>
  <kendo-tabstrip-tab title="Digitaal Uit" [selected]="this.selectedTab == 3">
    <ng-template kendoTabContent>
      <kanaal-selector [kanaalType]="this.do" [assign]="this.assign()" [system]="this.system()"
        (presentationChanged)="this.presentationChanged.emit($event)"
        [initialPresentation]="this.presentation() != 'hardware' ? this.presentation() : 'compact'"
        [limitPresentations]="['compact', 'detail']"></kanaal-selector>
    </ng-template>
  </kendo-tabstrip-tab>
  <kendo-tabstrip-tab title="Hardware" [selected]="this.selectedTab == 4">
    <ng-template kendoTabContent>
      <kanaal-selector [assign]="this.assign()" [system]="this.system()"
        (presentationChanged)="this.presentationChanged.emit($event)" [initialPresentation]="'hardware'"
        [limitPresentations]="['hardware']"></kanaal-selector>
    </ng-template>
  </kendo-tabstrip-tab>
</kendo-tabstrip>