<kendo-dialog *ngIf="isShowing()" width="1300px" (close)="this.onDone.emit(false)">
    <kendo-dialog-titlebar>
        <div class="kanaal-title">
            <div class="title">Kanalen</div>
            @if (!this.electronApi.isElectron()) {
                <button kendoButton (click)="this.open()">Nieuw Venster </button>
            }
            
        </div>
        
    </kendo-dialog-titlebar>
    <kanaal-overview [assign]="this.assign()" [system]="this.hdwConfigService.system()" 
        [presentation]="this.kanaalService.prefferedPresentation()"
        (presentationChanged)="this.onPresentationChanged($event)" />
    <kendo-dialog-actions>
        <button kendoButton (click)="this.onDone.emit(false)" themeColor="primary">Close</button>
    </kendo-dialog-actions>

</kendo-dialog>