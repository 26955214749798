import { Component, effect, Inject, input, output, signal } from '@angular/core';
import { JSBridgeService } from '../jsbridge.service';
import { ServerAPI } from '../../serverapi';

@Component({
  selector: 'legacy-list',
  templateUrl: './legacy-list.component.html',
  styleUrl: './legacy-list.component.scss'
})
export class LegacyListComponent {

  constructor(public jsBridgeService: JSBridgeService, @Inject('ServerApi') private serverApi: ServerAPI) {

    effect(() => {
      if (this.isEditing()) {
        this.generateList();
      }
    });
  }

  public async generateList() {
    this.jsBridgeService.getBridgeSync().save();
    let ret = await this.jsBridgeService.generateKopList();
    let klantNo = this.jsBridgeService.getKlantNo();
    if (ret != undefined && klantNo != undefined) {
      let listName = `/working/LST/${klantNo}.LST`;
      if (ret[listName] != undefined) {
        this.list = new TextDecoder().decode(ret[listName] as Uint8Array);
      }
      let dblName = `/working/LST/${klantNo}.DBL`;
      if (ret[dblName] != undefined) {
        this.dbl = new TextDecoder().decode(ret[dblName] as any);
      }
    }
    console.log(ret);
  }

  public isEditing = input<boolean>(false);
  public onDone = output<boolean>();

  public list = "";
  public dbl = "";

}
