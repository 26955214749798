import { Component, computed, input } from '@angular/core';
import { HwdSysteem, Kaart } from '../../hdw-config/HdwSysteem';
import { isSysteemKaart } from '../../hdw-config/hdw-konfig-types';
import { HwdConfigService } from '../../hwd-config.service';
import { minusOutlineIcon, trashIcon } from "@progress/kendo-svg-icons";
import { EditSystemService } from '../edit-system.service';

@Component({
  selector: 'kart-view',
  templateUrl: './kart-view.component.html',
  styleUrl: './kart-view.component.scss'
})
export class KartViewComponent {
  constructor(public editSystem: EditSystemService) { }

  public icon = trashIcon;

  public kaartId = input.required<number>();

  // public system = input.required<HwdSysteem>()

  public kaart = computed(() => {
    let id = this.kaartId();
    return this.editSystem.system()?.getKaart(id);
  })

  public dipSwitch = computed(() => {
    let kaart = this.kaart();
    if ((kaart != undefined)) { 
      if (kaart.dipSwitch != kaart.number) {
        return kaart.dipSwitch;
      }
    }
    return undefined;
  });

  onDelete() {
    let busType = this.kaart()?.type.bus;
    if (busType == undefined) {
      return;
    }
    this.editSystem.system.update(_system => {
      if (_system != undefined) {
        _system.deleteKaart(this.kaartId(), busType);
      }
      return _system;
    });
    // this.system().deleteKaart(this.kaartId(), busType);
  }
}
