import { sAnalogType, VectorsAnalogType } from "@wasm/KopWeb";
import { wasmVectorToArray } from "./wasmVector";
import { AnalogIn, ModBusIn, MqttIn } from "../props/PropTypes";


export type ANALOG_DEVICES = "ANALOG" | "MODBUS" | "MQTT";

export function aiTypeToKanaalType(sat: sAnalogType) {
    let device = sat.device as ANALOG_DEVICES;
    switch (device) {
        case 'MODBUS':
            return ModBusIn;

        case 'MQTT':
            return MqttIn;
        default:
            return AnalogIn;
    }

}

export class AITypes {
    private aiTypes: Array<sAnalogType> = [];
    constructor(vec: VectorsAnalogType) {
        this.aiTypes = wasmVectorToArray(vec);
    }

    public find(idx: number) {
        let ret = this.aiTypes.find(ait => ait.index == idx);
        if (ret != undefined) {
            return ret;
        }
        return this.aiTypes[0];
    }

    public findByGroup(gType: number) {
        return this.aiTypes.filter(ait => ait.groep == gType);
    }
}