import { Injectable } from '@angular/core';
import { analogeMeting, cAfdelingInfo, cAfdelingWM, cKC_Afdeling, cMetaService, ConfigProps, Losdef, sBijzPrg, Servo } from '@wasm/KopWeb';
import { JSBridgeService } from './jsbridge.service';
import { wasmVectorToArray } from '../utils/wasmVector';




export type AfdMeta = {
  ais: analogeMeting[];
  servos: Servo[];
  losDefs: Losdef[];
  extraConfigs: ConfigProps[];
  afdelingInfos: cAfdelingInfo[];
  drogWands: Losdef[];
  koelings: Losdef[];
  verwarmings: Losdef[];
  bijzPrgs: sBijzPrg[];
  losDefRaw: Losdef[];
};

@Injectable({
  providedIn: null
})
export class MetaService {

  constructor(private jsBridgeService: JSBridgeService) { 
  }

  private cache: Record<string, AfdMeta> = {};
  private metaService: cMetaService | undefined;

  afdelingMeta(afd: cKC_Afdeling | undefined) {
    if (afd != undefined) {
      let id = afd.type.value;
      let idStr = id.toString();
      if (this.cache[idStr] != undefined) {
        return this.cache[idStr];
      }
      let jsBridge = this.jsBridgeService.getBridgeSync();
      let wasm = this.jsBridgeService.wasmModule!;
      if (this.metaService == undefined) {
        this.metaService = jsBridge.getMetaService();
      }
      let allAIsIdx = this.metaService.getAIs(id);
      let allAIS = jsBridge.getAnalogeIngagen(allAIsIdx);
      allAIsIdx.delete();

      let allServosIdx = this.metaService.getServos(id);
      let allServos = jsBridge.getServos(allServosIdx);
      allServosIdx.delete();

      let allLDIdx = this.metaService.getLosDefinitions(id);
      let allLosDefs = jsBridge.getLosDefinitions(allLDIdx);
      allLDIdx.delete();

      let allDWIdx = this.metaService.getLosDefinitions(wasm.eAfdelingType.eAT_DROOGW.value);
      let allDrogWands = jsBridge.getLosDefinitions(allDWIdx);
      allDWIdx.delete();

      let allKoelIdx = this.metaService.getLosDefinitions(wasm.eAfdelingType.eAT_KOELING.value);
      let allKoeling = jsBridge.getLosDefinitions(allKoelIdx);
      allKoelIdx.delete();

      let allVerwarmingIndexes = this.metaService.getLosDefinitions(wasm.eAfdelingType.eAT_HEAT.value);
      let allVerwarming = jsBridge.getLosDefinitions(allVerwarmingIndexes);
      allVerwarmingIndexes.delete();

      let extraConfigs = jsBridge.getExtraConfigs(id);
      let afdInfo = jsBridge.getAfdelingsInfo();

      let allBijzPrgsIndxes = jsBridge.getBijzPrgsIndexes(id);
      let allBijzPrgs = jsBridge.getBijzPrgs(allBijzPrgsIndxes);
      let allLD = wasmVectorToArray(allLosDefs).sort((a, b) => a.volgorde - b.volgorde);
      let allDW = wasmVectorToArray(allDrogWands).sort((a, b) => a.volgorde - b.volgorde);
      let allK = wasmVectorToArray(allKoeling).sort((a, b) => a.volgorde - b.volgorde);
      let allV = wasmVectorToArray(allVerwarming).sort((a, b) => a.volgorde - b.volgorde);
      

      let ret =  {
        ais: wasmVectorToArray(allAIS),
        servos: wasmVectorToArray(allServos),
        losDefs: allLD,
        extraConfigs: wasmVectorToArray(extraConfigs),
        afdelingInfos: wasmVectorToArray(afdInfo),
        drogWands: allDW,
        koelings: allK,
        verwarmings: allV,
        bijzPrgs: wasmVectorToArray(allBijzPrgs).sort((a, b) => a.no - b.no),
        losDefRaw: allLD.concat(allDW).concat(allK).concat(allV)
      }
      allLosDefs.delete();
      allDrogWands.delete();
      allKoeling.delete();
      allVerwarming.delete();
      allAIS.delete();
      allServos.delete();
      allBijzPrgs.delete();
      afdInfo.delete();

      this.cache[id] = ret;

      return ret;
    }
    return {
      ais: [],
      servos: [],
      losDefs: [],
      extraConfigs: [],
      afdelingInfos: [],
      drogWands: [],
      koelings: [],
      verwarmings: [],
      bijzPrgs: [],
      losDefRaw: []
    }
  }
}
