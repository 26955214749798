import { Inject, inject, Injectable, signal } from '@angular/core';
import { concatAll, delay, forkJoin, from, map, Observable, Subscriber } from 'rxjs';
import loadLibrary, { cKopConfig, JSBridge, MainModule } from '@wasm/KopWeb';
import { AITypes } from '../utils/AITypes';
import { SERVO_TYPE_NAME, tServoKeys } from '../props/PropTypes';
import { ServerAPI } from '../serverapi';
import { PcibService } from './pcib.service';
import { KopListService } from './kop-list.service';
import { saveLocalFiles } from '../utils/wasmVector';

function makeAvailable(filePath: string, wasmModule: MainModule, electronAPI: ServerAPI) {
  let ret = electronAPI.getFile(filePath).then(data => {
    var stream = wasmModule.FS.open('/working/' + filePath, 'w+', undefined);
    if (data != undefined) {
      wasmModule.FS.write(stream, data, 0, data.length, 0, undefined);
      wasmModule.FS.close(stream);
    }
  });
  return ret;
}

@Injectable({
  providedIn: null
})
export class JSBridgeService {

  private klantNoSubscriber: (value: string | PromiseLike<string>) => void;
  private jsBridge: Observable<JSBridge>;
  private jsBridgeSync: JSBridge | undefined;

  public wasmModule: MainModule | undefined;

  private klantNo: string | undefined;

  public getKlantNo(): string {
    return this.klantNo!;
  }


  private aiTypes: AITypes | undefined;

  constructor(@Inject('ServerApi') private electronAPI: ServerAPI, private pcibService: PcibService, private kopListService: KopListService) {

    let resolve: any, reject: any;
    const promise = new Promise<string>((res, rej) => {
      resolve = res;
      reject = rej;
    });
    //let { resolve, promise } = Promise.withResolvers<string>();

    this.klantNoSubscriber = resolve;

    let tPromise = Promise.all([promise, loadLibrary()]).then(([klantNo, library]) => {
      this.wasmModule = library;
      let f1 = makeAvailable('db/KopData.db', library, this.electronAPI);
      let f2 = makeAvailable('db/KopData_en.db', library, this.electronAPI);
      let f3 = makeAvailable('db/KopTrans.db', library, this.electronAPI);
      let sxb = makeAvailable(`SXB/${klantNo}.SXB`, library, this.electronAPI);
      let tt = Promise.all([f1, f2, f3, sxb]).then(() => {
        console.log("JSbridge Instance");
        return new library.JSBridge(klantNo);
      });
      return tt;
    });
    this.jsBridge = from(tPromise)
      .pipe(delay(20))
      ;

    this.jsBridge.subscribe(bridge => {
      this.jsBridgeSync = bridge;
      this.aiTypes = new AITypes(this.jsBridgeSync.getAnalogeTypes());
      this.kopConfig.set(this.jsBridgeSync.getConfig());
      this.jsBridgeSync.saveXML(`${this.klantNo}.old.XML`);


      this.servoTypeName = Object.entries(this.wasmModule!.eServoSoort)
        .filter(([k, _]) => SERVO_TYPE_NAME[k as tServoKeys] != undefined)
        .map(([k, v]) => {
          let ret = { type: v.value, text: SERVO_TYPE_NAME[k as tServoKeys]! };
          return ret;
        })
        ;

    });
  }

  public servoTypeName: { type: number, text: string }[] = [];
  public kopConfig = signal<cKopConfig | undefined>(undefined, { equal: (a, b) => false });

  public async saveFile(filePath: string) {
    var data = this.wasmModule!.FS.readFile('/working/' + filePath, {
      encoding: 'binary'
    });
    return this.electronAPI.saveFile(filePath, data);
  }


  public getOLDXML() {
    var data = this.wasmModule!.FS.readFile(`${this.klantNo}.old.XML`, { encoding: 'utf8' });
    return data;
  }

  public getNewXML() {
    this.jsBridgeSync!.saveXML(`${this.klantNo}.new.XML`);
    var data = this.wasmModule!.FS.readFile(`${this.klantNo}.new.XML`, { encoding: 'utf8' });
    return data;
  }


  private addFolder(folder: string, files: Record<string, any>) {
    let t = this.wasmModule!.FS.readdir(folder);
    for (let f of t) {
      let fPath = `${folder}${f}`;
      let stat = this.wasmModule!.FS.stat(fPath, undefined);
      let isFile = this.wasmModule!.FS.isFile(stat.mode);
      if (isFile) {
        var data = this.wasmModule!.FS.readFile(fPath, {
          encoding: 'binary'
        });
        files[fPath] = data;
      }
      //console.log(`${fPath} : ${isFile}`);
    }

  }

  public updateVolgNummer(vn: number) {
    let kopConfig = this.kopConfig();
    if (kopConfig != undefined) {
      kopConfig.SetUnlockVolgnr(vn);
    }

  }



  public async compilePCIBBin() {
    if (this.klantNo != undefined) {
      let files: Record<string, any> = {};

      this.addFolder('/working/db/', files);
      this.addFolder('/working/SXB/', files);
      await this.pcibService.init(files);

      let ret = await this.pcibService.compilePCIB(this.klantNo);
      saveLocalFiles(ret, this.wasmModule!);
    }
  }

  public async generateKopList() {
    if (this.klantNo != undefined) {
      let files: Record<string, any> = {};

      this.addFolder('/working/db/', files);
      this.addFolder('/working/SXB/', files);

      await this.kopListService.init(files);

      let ret = await this.kopListService.generateList(this.klantNo);
      saveLocalFiles(ret, this.wasmModule!);
      return ret;
    }
  }

  public async compileBin() {
    let wasm = this.wasmModule!;
    if (this.klantNo != undefined) {
      let info = wasm.FS.analyzePath('/working/BIN', undefined);
      if (!info.exists) {
        wasm.FS.mkdir('/working/BIN', undefined);
      }
      let cpmBridge = new wasm.CompBridge(this.klantNo);
      cpmBridge.run();
      var data = this.wasmModule!.FS.readFile(`/working/BIN/${this.klantNo}.BEN`, {
        encoding: 'binary'
      });
      this.electronAPI.saveFile(`BIN/${this.klantNo}.BEN`, data);
      // var dataraw = this.wasmModule!.FS.readFile(`/working/BIN/${this.klantNo}.RAW`, {
      //   encoding: 'binary'
      // });
      // this.electronAPI.saveFile(`BIN/${this.klantNo}.RAW`, dataraw);

    }
  }

  public async unlock() {
    if (this.klantNo != undefined) {
      let wasm = this.wasmModule!;
      let info = wasm.FS.analyzePath('/working/HEX', undefined);
      if (!info.exists) {
        wasm.FS.mkdir('/working/HEX', undefined);
      }
      let cpmBridge = new wasm.CompBridge(this.klantNo);
      cpmBridge.unlock();
      console.log("HERE");
      let t = this.wasmModule!.FS.readdir('/working/HEX/');
      console.log(t);
      var dataraw = this.wasmModule!.FS.readFile(`/working/HEX/${this.klantNo}.HEX`, {
        encoding: 'binary'
      });
      this.electronAPI.saveFile(`HEX/${this.klantNo}.HEX`, dataraw);

    }
  }

  save() {
    this.jsBridgeSync!.save();
    return this.saveFile(`SXB/${this.klantNo}.SXB`);
  }

  public initKlantNo(klantNo: string) {
    this.klantNoSubscriber(klantNo);
    this.klantNo = klantNo;
  }
  getBridge() {
    return this.jsBridge;
  }

  getBridgeSync() {
    return this.jsBridgeSync!;
  }

  getAITypes() {
    return this.aiTypes!;
  }

}
