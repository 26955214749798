<div [class.io-wrapper-horizontal]="this.presentation() == 'horizontal'" [class.io-wrapper-vertical]="this.presentation() == 'vertical'">
@for (io of ios(); track $index) {
    <div class="io">
        <div class="io-label">{{io.ioType.toString()}}</div>
        @for (k of this.generateRange(io.start, io.range); track k) {
            <!-- @let label = io.ioType.toString() + ":" + k; -->
            @let label = this.kanaalLabel(k, io.ioType);
            @let cell = this.getAssignment(io.ioType, k);
            @let isSelect = this.isSingleKanaalSelected(k, io.ioType);
            <kanaal-assignement [cell]="cell" [isSelected]="isSelect" [label]="label" (click)="this.onDetailClick(k, io.ioType)"></kanaal-assignement>
        }
    </div>
}
</div>