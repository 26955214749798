<kendo-dialog *ngIf="isEditing()" title="List" width="1000px" (close)="this.onDone.emit(false)">
    <kendo-tabstrip [animate]="false">
        <kendo-tabstrip-tab title="List" [selected]="true">
            <ng-template kendoTabContent>
                <pre style="overflow-y: scroll; height:800px;">{{this.list}}</pre>
            </ng-template>
        </kendo-tabstrip-tab>
        <kendo-tabstrip-tab title="Dubbele Benoeming" [selected]="false">
            <ng-template kendoTabContent>
                <pre style="overflow-y: scroll; height:800px;">{{this.dbl}}</pre>
            </ng-template>
        </kendo-tabstrip-tab>
    </kendo-tabstrip>
    <kendo-dialog-actions>
        <button kendoButton (click)="this.onDone.emit(false)">Close</button>
    </kendo-dialog-actions>
</kendo-dialog>