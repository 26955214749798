import { Component, effect, ElementRef, input, output, ViewChild } from '@angular/core';
import { RangedKanaalAssign } from '../../props/PropTypes';
import { arrowOverflowDownIcon } from "@progress/kendo-svg-icons";
import { PopoverAnchorDirective } from '@progress/kendo-angular-tooltip';
@Component({
  selector: 'kanaal-assignement',
  templateUrl: './kanaal-assignement.component.html',
  styleUrl: './kanaal-assignement.component.scss'
})
export class KanaalAssignementComponent {

  constructor() {
    effect(() => {
      let isSelected = this.isSelected();
      if (isSelected && this.anchor != undefined) {
        this.anchor.hide();
      }
    });
  }

  @ViewChild("anchor", { static: false })
  private anchor: PopoverAnchorDirective | undefined;

  public icons = { arrowOverflowDownIcon: arrowOverflowDownIcon };
  public cell =  input.required<RangedKanaalAssign[]>();
  public isSelected = input<boolean>(false);
  public label = input<string>("");

  public click = output();
  public isClipped = false;

  @ViewChild('dwrapper') dwrapper: ElementRef | undefined;


  ngAfterViewInit() {
    if (this.dwrapper != undefined) {
      let offsetHeight = this.dwrapper.nativeElement.offsetHeight;
      let scrollHeight = this.dwrapper.nativeElement.scrollHeight;
      if (2 * offsetHeight < scrollHeight) {
        setTimeout(() => {  
          this.isClipped = true;  
        }, 10)
        
      }
    }
  }
}
