import { Inject, Injectable } from '@angular/core';
import loadLibrary, {  JSBridge, MainModule } from '@wasm/KopWeb_pcib';
import { ServerAPI } from '../serverapi';
import { saveLocalFiles } from '../utils/wasmVector';
// import { saveFiles } from '../utils/wasmVector';

@Injectable({
  providedIn: null
})
export class PcibService {
  private jsBridgeSync: JSBridge | undefined;
  public wasmModule: MainModule | undefined;

  constructor(@Inject('ServerApi') private electronAPI: ServerAPI) { }

  public async init(files: Record<string, any>) {
    console.log("init");
    if (this.jsBridgeSync == undefined) {
      if (this.wasmModule == undefined) {
        let lib = await loadLibrary();
        this.wasmModule = lib;          
      }
      saveLocalFiles(files, this.wasmModule!);
      // for (let p of Object.entries(files)) {
      //   let fPath = p[0];
      //   let data = p[1];
      //   var stream = this.wasmModule.FS.open(fPath, 'w+', undefined);
      //   if (data != undefined) {
      //     this.wasmModule.FS.write(stream, data, 0, data.length, 0, undefined);
      //     this.wasmModule.FS.close(stream);
      //   }
    
      // }
    }
  }

  public compilePCIB(klantNo: string) {
    console.log("test");
    let wasm = this.wasmModule;
    let ret: Record<string, any> = {};
    if (wasm != undefined) {
      let info = wasm.FS.analyzePath('/working/PCI', undefined);
      if (!info.exists) {
        wasm.FS.mkdir('/working/PCI', undefined);
      }
      let cpmBridge = new wasm.CompBridge(klantNo);
      cpmBridge.run();
      let data = this.wasmModule!.FS.readFile(`/working/PCI/${klantNo}.BIN`, {
        encoding: 'binary'
      });
      this.electronAPI.saveFile(`PCI/${klantNo}.BIN`, data);
      // data = this.wasmModule!.FS.readFile(`/working/PCI/${klantNo}.RAW`, {
      //   encoding: 'binary'
      // });
      // this.electronAPI.saveFile(`PCI/${klantNo}.RAW`, data);

      if (wasm.FS.analyzePath(`/working/PCI/${klantNo}.BIN`, undefined).exists) {
        let data = this.wasmModule!.FS.readFile(`/working/PCI/${klantNo}.BIN`, { encoding: 'binary' });
        ret[`/working/PCI/${klantNo}.BIN`] = data;
      }

      let t = wasm.FS.readdir('/working/PCI/');
      console.log(t);
    }
    return ret;


  }


}
