import { effect, Injectable, signal } from '@angular/core';
import { KopConfigService } from '../core/kop-config.service';
import { JSBridgeService } from '../core/jsbridge.service';
import { AnalogIn, AnalogOut, AssignementType, DigitalIn, DigitalOut, IOBaseType, IODirection, IOType, KanaalUpdate, ModBusOut, RangedKanaal, RangedKanaalAssign } from '../props/PropTypes';
import { Alarm, cAfdelingWM, cKC_Afdeling, cKC_Alarm, cKC_LosDef, cKC_LosDefs, cKopConfig, cKopConfigModules, eKanaalType } from '@wasm/KopWeb';
import { wasmMapForEach, wasmMapToRecord, wasmVectorForEach, wasmVectorToArray } from '../utils/wasmVector';
import { AfdMeta, MetaService } from '../core/meta.service';
import { kanaalLabel, rangedKanaalLabel } from './kanaal.utils';
import { aiTypeToKanaalType } from '../utils/AITypes';

export type KSelectorPresentationType = "compact" | "detail" | "hardware";

@Injectable({
  providedIn: null
})
export class KanaalService {

  constructor(public jsBridgeService: JSBridgeService, private metaService: MetaService) {
  }

  public prefferedPresentation = signal<KSelectorPresentationType>("compact");

  public kanaalLabel(kanaal: number, kType: IOType): string {
    return kanaalLabel(kanaal, kType);
  }

  public rangedKanaalLabel(rangedKanaal: RangedKanaal): string {
    return rangedKanaalLabel(rangedKanaal);
  }

  applyUpdates(kfg: cKopConfigModules, updates: KanaalUpdate[]) {
    let afdelings = wasmVectorToArray(kfg.afdelings);
    for (let afd of afdelings) {
      this.applyAfdUpdates(afd, updates);
    }
  }

  applyAfdUpdates(afdWM: cAfdelingWM, updates: KanaalUpdate[]) {
    let metas = this.metaService.afdelingMeta(afdWM.afdeling());

    this.applyLosDefUpdates(afdWM.afdeling().losDefs, metas, updates);
    this.applyLosDefUpdates(afdWM.afdeling().koeling, metas, updates);
    this.applyLosDefUpdates(afdWM.afdeling().droogWand, metas, updates);
    this.applyLosDefUpdates(afdWM.afdeling().verwarming, metas, updates);

    this.applyAIUpdates(afdWM, metas, updates);
    this.applyServoUpdates(afdWM, metas, updates);
    this.applyBijzPrgmUpdates(afdWM, metas, updates);
    this.applyAlarmUpdates(afdWM, updates);
  }


  applyAIUpdates(afdWM: cAfdelingWM, metas: AfdMeta, updates: KanaalUpdate[]) {
    let afd = afdWM.afdeling();
    for (let idx = 0; idx < afd.analogs.size(); idx++) {
      let ai = afd.analogs.at(idx);
      let aiType = this.jsBridgeService.getAITypes().find(ai.typeIndex);
      let kanaalType = aiTypeToKanaalType(aiType);
      if (AnalogIn.equals(kanaalType)) {
        let meta = metas.ais.find(m => m.index == ai.index);
        if (meta != undefined) {
          let tmp: RangedKanaal = {
            ioType: AnalogIn, start: ai.kanaal, range: ai.range
          };
          let validUpdates = this.findUpdates(tmp, updates);
          if (validUpdates != undefined) {
            ai.kanaal = ai.kanaal + validUpdates.offset;
          }
        }          
      }

    }
  }

  applyLosDefUpdates(losDefs: cKC_LosDefs, metas: AfdMeta, updates: KanaalUpdate[]) {
    for (let idx = 0; idx < losDefs.size(); idx++) {
      let ld = losDefs.at(idx);
      let meta = metas.losDefRaw.find(m => m.index == ld.index);
      if (meta != undefined) {
        let type = this.getIOTypeFromParamType(meta.type);
        if (type != undefined) {
          let tmp: RangedKanaal = {
            ioType: type, start: ld.waarde, range: ld.range,
          }
          let validUpdates = this.findUpdates(tmp, updates);
          if (validUpdates != undefined) {
            ld.waarde = ld.waarde + validUpdates.offset;
          }
        }
      }
    }
  }


  applyServoUpdates(afdWM: cAfdelingWM, metas: AfdMeta, updates: KanaalUpdate[]) {
    let afd = afdWM.afdeling();
    // let metas = this.metaService.afdelingMeta(afdWM);
    for (let idx = 0; idx < afd.servos.size(); idx++) {
      let s = afd.servos.at(idx);
      let type = this.getServoKanaalType({ soort: s.soort.value, uitgang: s.uitgang });
      let meta = metas.servos.find(m => m.index == s.index);
      if (type != undefined && meta != undefined) {
        let validUpdates = this.findUpdates(type, updates);
        if (validUpdates != undefined) {
          s.uitgang = s.uitgang + validUpdates.offset;
        }
      }
      for (let sp of wasmVectorToArray(s.params.keys())) {
        let param = s.params.at(sp);
        let type = this.getIOTypeFromParamType(param?.type);
        let pmeta = meta?.param.get(sp);
        if (meta != undefined && type != undefined && pmeta != undefined && type != undefined && param != undefined) {
          let tmp: RangedKanaal = {
            ioType: type, start: param.waarde, range: param.range
          };
          let validUpdates = this.findUpdates(tmp, updates);
          if (validUpdates != undefined) {
            param.waarde = param.waarde + validUpdates.offset;
          }
        }
      }
    }
  }

  applyBijzPrgmUpdates(afdWM: cAfdelingWM, metas: AfdMeta, updates: KanaalUpdate[]) {
    let afd = afdWM.afdeling();
    for (let idx = 0; idx < afd.bijzPrgs.size(); idx++) {
      let bp = afd.bijzPrgs.at(idx);
      let meta = metas.bijzPrgs.find(m => m.index == bp.index);
      for (let bpp of wasmVectorToArray(bp.params.keys())) {
        let param = bp.params.at(bpp);
        let type = this.getIOTypeFromParamType(param?.type);
        let pmeta = meta?.param.get(bpp);
        if (meta != undefined && type != undefined && pmeta != undefined && type != undefined && param != undefined) {
          let tmp: RangedKanaal = {
            ioType: type, start: param.waarde, range: param.range
          };
          let validUpdates = this.findUpdates(tmp, updates);
          if (validUpdates != undefined) {
            param.waarde = param.waarde + validUpdates.offset;
          }
        }
      }
    }
  }

  applyAlarmUpdates(afdWM: cAfdelingWM, updates: KanaalUpdate[]) {
    let afd = afdWM.afdeling();
    // let alarms = wasmVectorToArray(afd.alarms);
    let jsBridge = this.jsBridgeService.getBridgeSync();
    let alarmsMeta = wasmVectorToArray(jsBridge.getAlarms(afd.type.value))!;

    for (let idx = 0; idx < afd.alarms.size(); idx++) {
      // for (let a of alarms) {
      let a = afd.alarms.at(idx);
      let meta = alarmsMeta.find(al => al.index == a.index);
      if (meta != undefined) {
        let tmp: RangedKanaal = {
          ioType: DigitalIn, start: a.kanaal, range: 1
        };
        let validUpdates = this.findUpdates(tmp, updates);
        if (validUpdates != undefined) {
          a.kanaal = a.kanaal + validUpdates.offset;
        }
      }
    }
  }


  findUpdates(kanaal: RangedKanaal, updates: KanaalUpdate[]) {
    return updates.find(u => u.ranged.ioType.equals(kanaal.ioType) && u.ranged.start + u.ranged.range > kanaal.start && u.ranged.start <= kanaal.start);
  }

  getKonfigKanalen(kfg: cKopConfig) {
    let ret: Array<RangedKanaalAssign> = [];
    let afdls = kfg.afdls;
    wasmVectorForEach(afdls, afd => {
      ret = ret.concat(this.getAfdelingKanalen(afd));
    });
    return ret;
  }

  getAfdelingKanalen(afd: cKC_Afdeling) {
    let ret: Array<RangedKanaalAssign> = [];

    let metas = this.metaService.afdelingMeta(afd);


    let losdefs = this.getAfdelingLosDef(afd, afd.losDefs, metas, 'losdef');
    let droogWand = this.getAfdelingLosDef(afd, afd.droogWand, metas, 'droogwand');
    let koeling = this.getAfdelingLosDef(afd, afd.koeling, metas, 'koeling');
    let verwarming = this.getAfdelingLosDef(afd, afd.verwarming, metas, 'verwarming');
    // Lose Defs
    let aiKanalen = this.getAfdelingAIKanalen(afd, metas);
    let servoKanalen = this.getAfdelingServoKanalen(afd, metas);
    let bijProgKanalen = this.getBijzProgKanalen(afd, metas);
    let alarmenKanalen = this.getAlarmsKanalen(afd);

    // AIs
    return ret.concat(aiKanalen)
      .concat(losdefs).concat(droogWand).concat(koeling).concat(verwarming)
      .concat(servoKanalen).concat(bijProgKanalen)
      .concat(alarmenKanalen);
  }

  getAfdelingLosDef(afd: cKC_Afdeling, losdefs: cKC_LosDefs, metas: AfdMeta, assignementType: AssignementType) {
    let ret: Array<RangedKanaalAssign> = [];
    wasmVectorForEach(losdefs, ld => {
      let meta = metas.losDefRaw.find(m => m.index == ld.index);
      if (meta != undefined) {
        let type = this.getIOTypeFromParamType(meta.type);
        if (type != undefined) {
          let tmp: RangedKanaalAssign = {
            rangedKnaal: { ioType: type, start: ld.waarde, range: ld.range },
            assignement: {
              index: ld.index,
              omschrijving: meta.omschr.toString(),
              type: assignementType,
              afdRefNr: afd.refNr,
              afdeling: afd.titel.toString()
            }
          };
          ret.push(tmp);
        }
      }

    });

    return ret;

  }
  getAfdelingAIKanalen(afd: cKC_Afdeling, metas: AfdMeta) {
    let ret: Array<RangedKanaalAssign> = [];
    wasmVectorForEach(afd.analogs, ai => {
      let meta = metas.ais.find(m => m.index == ai.index);
      if (meta != undefined) {
        let aiType = this.jsBridgeService.getAITypes().find(ai.typeIndex);
        let kanaalType = aiTypeToKanaalType(aiType);
        let tmp: RangedKanaalAssign = {
          rangedKnaal: { ioType: kanaalType, start: ai.kanaal, range: ai.range },
          assignement: {
            index: ai.index,
            omschrijving: meta.omschrij.toString(),
            type: 'ai',
            afdRefNr: afd.refNr,
            afdeling: afd.titel.toString()
          }
        };
        ret.push(tmp);
      }

    })
    return ret;

  }
  getAfdelingServoKanalen(afd: cKC_Afdeling, metas: AfdMeta) {
    let ret: Array<RangedKanaalAssign> = [];
    // let servo = wasmVectorToArray(afd.servos);
    // let metas = this.metaService.afdelingMeta(afdWM);
    wasmVectorForEach(afd.servos, s => {
      let type = this.getServoKanaalType({ soort: s.soort.value, uitgang: s.uitgang });
      let meta = metas.servos.find(m => m.index == s.index);
      if (type != undefined && meta != undefined) {
        let tmp: RangedKanaalAssign = {
          rangedKnaal: type,
          assignement: {
            index: s.index,
            omschrijving: meta.omschr.toString(),
            type: 'servo',
            afdRefNr: afd.refNr,
            afdeling: afd.titel.toString()
          }
        };
        ret.push(tmp);
      }

      wasmMapForEach(s.params, (k, v) => {
        let sp = k;
        let param = v;

        let type = this.getIOTypeFromParamType(param?.type);
        let pmeta = meta?.param.get(sp);
        if (meta != undefined && type != undefined && pmeta != undefined && type != undefined && param != undefined) {
          let tmp: RangedKanaalAssign = {
            rangedKnaal: { ioType: type, start: param.waarde, range: param.range },
            assignement: {
              index: sp,
              omschrijving: meta.omschr.toString() + ' ' + pmeta.omschr.toString(),
              type: 'servo',
              afdRefNr: afd.refNr,
              afdeling: afd.titel.toString()
            }
          };
          ret.push(tmp);
        }

      });
    }
    )
    return ret;
  }

  getBijzProgKanalen(afd: cKC_Afdeling, metas: AfdMeta) {
    let ret: Array<RangedKanaalAssign> = [];
    let bijzPrgs = wasmVectorToArray(afd.bijzPrgs);
    // let metas = this.metaService.afdelingMeta(afdWM);
    wasmVectorForEach(afd.bijzPrgs, (bp) => {
      let meta = metas.bijzPrgs.find(m => m.index == bp.index);
      wasmMapForEach(bp.params, (k, v) => {
        let param = v;
        let bpp = k;
        let type = this.getIOTypeFromParamType(param?.type);
        let pmeta = meta?.param.get(bpp);
        if (meta != undefined && type != undefined && pmeta != undefined && type != undefined && param != undefined) {
          let tmp: RangedKanaalAssign = {
            rangedKnaal: { ioType: type, start: param.waarde, range: param.range },
            assignement: {
              index: bp.index,
              omschrijving: meta.omschr.toString() + ' ' + pmeta.omschr.toString(),
              type: 'bijzPrg',
              afdRefNr: afd.refNr,
              afdeling: afd.titel.toString()
            }
          };
          ret.push(tmp);
        }
      });
    });

    return ret;
  }

  getTextForAlarm(afd: cKC_Afdeling, a: cKC_Alarm | undefined, meta: Alarm | undefined) {
    if (a == undefined) return "";
    if (meta == undefined) return "";
    let kopConfig = this.jsBridgeService.kopConfig();
    if (kopConfig != undefined) {
      let globalAlarmTeksten = wasmMapToRecord<string>(kopConfig.gAlarmTeksten, (e: any) => e.toString());
      let afdTeksten = wasmMapToRecord(afd.alarmTexts, (e: any) => e.toString());
      Object.entries(afdTeksten).forEach(([key, val]) => {
        globalAlarmTeksten[parseInt(key) + 20] = val;
      });
      let vrijText = globalAlarmTeksten[meta.vrijetext - 1] ?? '';
      return `${vrijText} - V(${meta.vrijetext})`;
    }
    return "";
  }

  getAlarmsKanalen(afd: cKC_Afdeling) {
    let ret: Array<RangedKanaalAssign> = [];
    let jsBridge = this.jsBridgeService.getBridgeSync();
    let alarmsMetaWasm = jsBridge.getAlarms(afd.type.value);
    let alarmsMeta = wasmVectorToArray(alarmsMetaWasm)!;
    wasmVectorForEach(afd.alarms, a => {
      let meta = alarmsMeta.find(al => al.index == a.index);
      if (meta != undefined) {
        let tmp: RangedKanaalAssign = {
          rangedKnaal: { ioType: DigitalIn, start: a.kanaal, range: 1 },
          assignement: {
            index: a.index,
            omschrijving: this.getTextForAlarm(afd, a, meta),
            type: 'alarm',
            afdRefNr: afd.refNr,
            afdeling: afd.titel.toString()
          }
        };
        ret.push(tmp);
      }
    });
    alarmsMetaWasm.delete();
    return ret;
  }

  getServoKanaalType(servo: { soort: number, uitgang: number }): RangedKanaal | undefined {
    var type: IOType;
    var wasm = this.jsBridgeService.wasmModule!;
    switch (servo.soort) {
      case wasm.eServoSoort.eSS_SERVO.value:
        return {
          ioType: DigitalOut,
          start: servo.uitgang,
          range: 2
        }
        break;
      case wasm.eServoSoort.eSS_PULS.value:
      case wasm.eServoSoort.eSS_OPDI.value:
        return {
          ioType: DigitalOut,
          start: servo.uitgang,
          range: 1
        }
        break;
      case wasm.eServoSoort.eSS_ANA0T10.value:
      case wasm.eServoSoort.eSS_ANA2T10.value:
        return {
          ioType: AnalogOut,
          start: servo.uitgang,
          range: 1
        }
        break;
      case wasm.eServoSoort.eSS_LLBUS.value:
        return {
          ioType: ModBusOut,
          start: servo.uitgang,
          range: 1
        }
    }

    return;
  }


  getIOTypeFromParamType(type?: number): IOType | undefined {
    if (type == undefined) {
      return;
    }
    let sw = type & 0x07;
    switch (sw) {
      case 0x01: return AnalogIn;    // ana in
      case 0x02: return AnalogOut;   // ana uit
      case 0x05: return DigitalIn;   // dig in
      case 0x06: return DigitalOut;   // dig uit
    }
    return;
  }


  getKCParamType(servoParam: {
    type: number,
    waarde: number,
    range: number
  }): RangedKanaal | undefined {
    let ioType: IOType | undefined = this.getIOTypeFromParamType(servoParam.type);
    if (ioType != undefined) {
      return {
        ioType: ioType,
        range: servoParam.range,
        start: servoParam.waarde
      }
    }

    return;
  }

  ioType2Kanaal(type: IOType) {
    let wasm = this.jsBridgeService.wasmModule!;
    var kType: eKanaalType = wasm.eKanaalType.AI;
    if ((type.direction == IODirection.IN) && (type.type == IOBaseType.Digital)) {
      kType = wasm.eKanaalType.DI;
    } else if ((type.direction == IODirection.OUT) && (type.type == IOBaseType.Digital)) {
      kType = wasm.eKanaalType.DO;
    } else if ((type.direction == IODirection.OUT) && (type.type == IOBaseType.Analog)) {
      kType = wasm.eKanaalType.AO;
    }

    return kType;
  }


  getInvTab(kanaal: number, type: IOType) {
    var kType = this.ioType2Kanaal(type);
    return this.jsBridgeService.getBridgeSync().getInvTab(kanaal, kType);
  }

  setInvTab(kanaal: number, type: IOType, inv: boolean) {
    var kType = this.ioType2Kanaal(type);
    this.jsBridgeService.getBridgeSync().setInvTab(kanaal, kType, inv);
  }

}
