import { Component, computed, effect, input, output, signal } from '@angular/core';
import { ModuleBijzPrg, ModuleParam, RangedKanaalInput } from '../PropTypes';
import { KopConfigService } from '../../core/kop-config.service';
import { JSBridgeService } from '../../core/jsbridge.service';
import { KopModulesService } from '../../modules/kop-modules.service';
import { wasmVectorToArray } from '../../utils/wasmVector';
import { KanaalService } from '../../kanaal/kanaal.service';
import { sBijzPrg } from '@wasm/KopWeb';

@Component({
  selector: 'bijz-prgms-editor',
  templateUrl: './bijz-prgms-editor.component.html',
  styles: ``
})
export class BijzPrgmsEditorComponent {


  constructor(public kopConfigService: KopConfigService, public jsBridgeService: JSBridgeService, 
      public kopModulesService: KopModulesService, public kanaalService: KanaalService) {
    effect(() => {
      let initValue = this.initialValue();
      if (initValue != undefined) {
        this.editValue = initValue; 
        this.valueChanged.emit(this.editValue);
      }
    });
  }
  public isNew = input<boolean>(false);
  public editOnly = input<boolean>(false);
  public valueChanged  = output<ModuleBijzPrg>();
  public isEditing = false;

  public initialValue = input<ModuleBijzPrg>();

  public editValue: ModuleBijzPrg = {
    index: 0,
    params: {}
  };


  public newBPOptions = computed(() => {
    if (this.isNew()) {
      let bpMetas = this.kopConfigService.currentMeta().bijzPrgs;
      return bpMetas;
    }
    return [];
  });

  alreadySelectedFN() {
    let tmBPs = this.kopConfigService.selectedAfd()?.afdeling().bijzPrgs;
    if (tmBPs != undefined) {
      let bps = wasmVectorToArray(tmBPs);
      let ret = (idx: number) => {
        return bps.find(a => a.index == idx) != undefined;
      }
      return ret;
    }
    return (idx: number) => false;
  }

  labelFn() {
    let ret = (meta: any) => (meta as sBijzPrg).omschr.toString();
    return ret;
  }



  private _metaIdx = signal<number | undefined>(undefined);

  public metaIdx = input<number | undefined>(undefined);

  private newMetaIdx = computed(() => {
    if (this._metaIdx() != undefined) {
      return this._metaIdx();
    }
    return this.metaIdx();
  });

  public meta = computed(() => {
    let bp = this.initialValue();
    if (bp != undefined) {
      let meta = this.kopConfigService.currentMeta();
      return meta.bijzPrgs.find(m => m.index == bp.index);
    }
    let chosenMeta = this.newMetaIdx();
    if (chosenMeta != undefined) {
      let meta = this.kopConfigService.currentMeta();
      return meta.bijzPrgs.find(m => m.index == chosenMeta);
    }
  });

  public paramsMeta = computed(() => {
    let meta = this.meta();
    if (meta != undefined) {
      return wasmVectorToArray(meta.param);
    }
    return [];
  });

  servoKCTypeLabel(sp: ModuleParam) {
    let kanaalType = this.kanaalService.getKCParamType(sp);
    if (kanaalType == undefined) {
      return "Parameter";
    }
    return `${kanaalType?.ioType.type} ${kanaalType?.ioType.direction}`;
  }


  public onParamKanaalChange(event: RangedKanaalInput, pNo: number) {
    this.editValue.params[pNo].inverted = event.inverted == true;
    this.editValue.params[pNo].waarde = event.start;
  }


  onNew(e: { index: number }) {
    let idx = e.index;
    this._metaIdx.set(idx);
    this.editValue = this.kopConfigService.buildDefaultBijzPrgm(idx);
    this.isEditing = true;
  }

  onEdit() {
    this.isEditing = true;
  }

  onDelete() {
    let bp = this.initialValue();
    this.kopConfigService.selectedAfd.update(afd => {
      if ((bp != undefined) && (afd != undefined)) {
        let bijzPrgs = afd.afdeling().bijzPrgs;
        let old = wasmVectorToArray(afd.afdeling().bijzPrgs);
        let idx = bp.index;
        bijzPrgs.clear();
        for (let s of old) {
          if (s.index != idx) {
            bijzPrgs.push_back(s);
          }
        }
      }
      return afd;
    });
  }

  onSave() {
    this.isEditing = false;
    console.log(this.editValue);
    let meta = this.meta();
    if (meta != undefined) {
      this.kopConfigService.upsertBijzPrgm(this.editValue, meta);
    }
  }
}
